<template>
    <v-app-bar
        app
        fixed
        color="#018656"
        dark
        >
        <!-- color="#1e88e5" Azul -->
        <!-- color="#018656" Verde -->
        <v-app-bar-nav-icon
            @click.stop="$emit('drawer', !show)"
        ></v-app-bar-nav-icon>
        <v-toolbar-title
            v-model="factory"
        >
            <router-link
                to="/"
            >
                {{ codigo }} - {{ razao_social }}
            </router-link>
                <!-- {{ factory }} -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
            @click="logout"
        >
            <v-icon>
                fas fa-sign-out-alt
            </v-icon>
        </v-app-bar-nav-icon>
    </v-app-bar>
</template>

<script>
import { userKey, baseApiUrl } from '@/global';
import axios from "axios";

export default {
    name: 'Header',
    factory: '',
    data: () => ({
        factory: '',
        codigo_empresa: '',
        codigo: "",
        razao_social: "",
    }),
    props: {
        show: Boolean,
    },
    model: {
        prop: 'show',
        event: 'drawer',
    },
    created() {
        this.codigo_empresa = Number(this.$store.state.cod_empresa);
    },
    mounted () {
        this.factory = this.$store.state.name;
        this.carregarEmpresa();
    },
    methods: {
        logout () {
            localStorage.removeItem(userKey)
            localStorage.removeItem("factory")
            localStorage.removeItem("name")
            this.$store.state.cod_empresa = "";
            this.$store.state.name = "";
            this.$store.state.token_sisfrete = "";
            this.$store.state.token_persys = "";
            this.$router.push('/login')
        },

        async carregarEmpresa(){
            await axios.get(`${baseApiUrl}/empresas?page=1&cod_empresa=${this.$store.state.cod_empresa}`)
            .then((res) => {
                this.codigo = res.data.empresas.data[0].codigo,
                this.razao_social = res.data.empresas.data[0].razao_social
            })
        }
    }
}
</script>

<style>
    .v-toolbar__content i, .v-toolbar__title a {
        font-size: .875rem !important;
    }
    .v-toolbar__title a {
        color: #FFF !important;
        text-decoration: none !important;
    }
</style>