var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.modal.sendMail),callback:function ($$v) {_vm.$set(_vm.modal, "sendMail", $$v)},expression:"modal.sendMail"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Manifestação Email ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":['Confirmação', 'Resposta', 'Pagamento', 'Prazo'],"label":"Tipo","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"pt-br"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Assunto","hide-details":"auto","type":"text","clearable":""},model:{value:(_vm.assunto),callback:function ($$v) {_vm.assunto=$$v},expression:"assunto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","name":"descricao","label":"Descrição"},model:{value:(_vm.descricao),callback:function ($$v) {_vm.descricao=$$v},expression:"descricao"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.modal.sendMail = false}}},[_vm._v(" Voltar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modal.sendMail = false}}},[_vm._v(" Salvar ")])],1)],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"px-6",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.grid.manifesto.cabecalho,"items":_vm.grid.manifesto.items,"hide-default-footer":"","loading":_vm.grid.manifesto.carregando,"item-key":"id"},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarDataBR(item.data))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('v-icon',{staticClass:"acoes",attrs:{"size":"12"}},[_vm._v("fas fa-envelope-open")])],1)]}}],null,true)},[_c('span',[_vm._v("Já Lido")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('v-icon',{staticClass:"acoes",attrs:{"size":"12"},on:{"click":function($event){return _vm.MarkAsRead(item)}}},[_vm._v("fas fa-envelope")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar como Lido")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('v-icon',{staticClass:"acoes",attrs:{"size":"12","color":"green"},on:{"click":function($event){return _vm.MarkPagto(item)}}},[_vm._v("fas fa-money-bill-wave")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar para Pagamento")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('v-icon',{staticClass:"acoes",attrs:{"size":"12","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fas fa-hourglass-end")])],1)]}}],null,true)},[_c('span',[_vm._v("Encerrar Reclamação")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }