<template>
  <div>
    <!-- Inicio Ocorrencias -->
    <v-card class="elevation-0">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" sm="5" xl="4" style="padding-top: 0px;" class="d-flex">
            <fieldset style="width: 100%; display: flex; align-items: flex-end;">
              <legend class="legendStyle"><b>Detalhes</b></legend>
              <v-data-table dense :headers="status.cabecalho" :items="statusIn" hide-default-footer
                class="elevation-0 pt-4" :loading="status.carregando">

                <template #header.analisar="{ header }">
                  <span>{{ header.text }}</span>
                </template>

                <template #item.status="{ item }">
                  <span v-if="item.status == 'I'">Importado</span>
                  <span v-if="item.status == 'A'">Aberto</span>
                  <span v-if="item.status == 'D'">Entregue</span>
                  <span v-if="item.status == 'E'">Erro</span>
                  <span v-if="item.status == 'F'">Finalizado</span>
                  <span v-if="item.status == 'R'">Reprocessar</span>
                  <span v-if="item.status == 'C'">Cancelado</span>
                </template>

                <template #item.cod_situacao="{ item }">
                  <span v-if="item.cod_situacao == 0">Erro/Pausado</span>
                  <span v-if="item.cod_situacao == 1">Data Prevista</span>
                  <span v-if="item.cod_situacao == 2">Aberto/Rastreio</span>
                  <span v-if="item.cod_situacao == 3">Entregue/Finalizado</span>
                </template>

                <template #item.time_line="{ item }">
                  <span v-if="item.time_line == 0">Aguardando Postagem</span>
                  <span v-if="item.time_line == 1">Postado</span>
                  <span v-if="item.time_line == 2">Transporte</span>
                  <span v-if="item.time_line == 3">Saiu Entrega</span>
                  <span v-if="item.time_line == 4">Entregue</span>
                </template>

                <template #item.analisar="{}">
                  <div>
                    <span v-if="isAnalisar || statusIn[0].analisar == 1">Sim</span>
                    <span v-else @click="setAnalise()">Não</span>
                  </div>
                </template>
              </v-data-table>

              <v-spacer></v-spacer>

              <v-btn v-if="statusIn[0].devolvido == 1 || encerrar == true"
              tile small dense @click="objectEncerrar()" class="mb-2 mr-2">
                Finalizar
              </v-btn>
            </fieldset>
          </v-col>

          <v-col cols="12" md="7" sm="7" xl="8" style="padding-top: 0px;" class="d-flex">
            <fieldset class="fieldAlertas">
              <legend class="legendStyle">Alertas</legend>     

              <v-chip label v-for="(index, ocorrencia) in ocorIn[0]" v-if="index > 0" :color="index > 0 ? 'red' : ''">
                <span :class="index > 0 ? 'white--text': ''">{{ ocorrencia }}</span>
              </v-chip>
            </fieldset>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim Ocorrencias -->
  </div>
</template>

<script crossorigin="anonymous">
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from 'moment';

export default {
  name: "Ocorrencias",
  props: {
    nroobjeto: { type: Number },
    statusIn: { type: Array },
    ocorIn: { type: Array },
    encerrar: { type: Boolean },
  },
  data: () => ({
    analiseObjeto: 0,
    isAnalisar: false,
    showAnalise: "",
    status: {
      cabecalho: [
        { text: "Status", value: "status", sortable: false },
        { text: "Situação", value: "cod_situacao", sortable: false },
        { text: "Time Line", value: "time_line", sortable: false },
        { text: "Analisar", value: "analisar", sortable: false },
      ],
      items: [],
      carregando: false,
      paginaAtual: 1,
    },
  }),

  methods: {
    async AnalisarObjeto(){
      let url = `${baseApiUrl}/objetos-analisar`;
      url += `?objeto=${this.nroobjeto}`;

      await axios.patch(url, {
          analisar: this.analiseObjeto,
        }, {
        header: { 'Content-Type' : 'application/json',  }
        })
        .then(() => {
          this.isAnalisar = true;
        })
    },

    async objectEncerrar(){
      let url = `${baseApiUrl}/objetos-encerrar?objeto=${this.nroobjeto}`;
      let data = moment(new Date()).format("YYYY-MM-DD");

      this.$swal({
        title: 'Confirmação',
        text: `Tem certeza que deseja finalizar esse objeto?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          await axios
            .patch(url, {
              "status": "F",
              "data_encerramento": data,
            })
              .then(() => {
                showSuccess("Objeto finalizado com sucesso");
              }).catch(() => {
                showError("Não foi possível finalizar o objeto");
              }
            )
        }
      });
    },

    setAnalise(){
      if(this.analiseObjeto == 0)
        this.analiseObjeto = 1;
      else
        this.analiseObjeto = 0;

      this.AnalisarObjeto();
    },

    getOpacity(){
      if(this.analiseObjeto == 0){
        return "opacity: 0.5";
      }else{
        return "opacity: 1.0";
      }
    }
  },
}
</script>

<style>
.legendStyle {
  margin-left: 5px;
  padding-inline: 8px;
  font-weight: bolder;
}
.fieldAlertas {
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}
</style>