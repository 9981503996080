<template>
    <div>
        <Header
            v-model="show"
        />
        <Menu
            v-model="show"
        />
        <v-container
            fluid
        >
            <v-flex
                xs12
            >
                <slot />
            </v-flex>
        </v-container>
    </div>
</template>

<script>
import Header from '@/components/_templates/Header'
import Menu from '@/components/_templates/Menu'

export default {
    name: 'Template',
    components: {
        Header,
        Menu,
    },
    data () {
        return {
            show: false
        }
    }
}
</script>