<template>
  <div>
    <!-- Início send -->
    <v-dialog v-model="sendComplaint" width="800">
      <v-card>
        <v-card-title class="text-h5 red lighten-2" align="center">
          Enviar Reclamação
        </v-card-title>
        <v-card-text>
          <p class="text--primary" align="center">
            Confirmar a Reclamação para
          </p>
          <v-row></v-row>
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px">
              Objeto: {{ reclamacao.numero_rastreio }}
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
              Motivo: {{ reclamacao.codigo }} - {{ this.reclamacao_motivo }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="sendComplaint = false">
            Voltar
          </v-btn>
          <v-btn color="primary" text @click="salvarReclamacao(), (sendComplaint = false)">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim send -->

    <!-- Início manifesto -->
    <v-dialog v-model="manifesto" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Reativação Protocolo {{ consulta.item.protocolo_retorno }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row height="70">
              <v-col cols="12" md="6">
                <v-dialog ref="modal_dt_manifesto" v-model="mod_dt_manifesto"
                  :return-value.sync="reativacao.dt_manifesto" persistent width="290px" :retain-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined prepend-icon="fas fa-calendar-alt" label="Data" readonly v-bind="attrs"
                      v-on="on" :value="formatDate" clearable v-validate="'required'"
                      :rules="[(v) => !!v || 'Data obrigatória!']" required></v-text-field>
                  </template>
                  <v-date-picker v-model="reativacao.dt_manifesto" scrollable :min="dateStartMan">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mod_dt_manifesto = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.modal_dt_manifesto.save(reativacao.dt_manifesto)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="itensTipo" label="Tipo" v-model="reativacao.tipo" item-text="descricao"
                  item-value="id" v-validate="'required'" :rules="[(v) => !!v || 'Preenchimento Obrigatório!']"
                  required></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select dense label="Motivos para abrir Reclamação" :items="itensReclamacao"
                  prepend-icon="fas fa-bullhorn" v-model="reativacao.codigo" item-text="fullmotivos" item-value="id"
                  outline v-validate="'required'" :rules="[(v) => !!v || 'Preenchimento Obrigatório!']"
                  required></v-select>
              </v-col>
            </v-row>
            <v-row>
              <!-- Botões msgs prontas -->
              <v-col cols="12" md="1">
                <v-row v-for="nbtn in 2" :key="nbtn">
                  <v-col cols="auto">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn height="40" variant="text" width="40" fab v-on="on" @click="getMsg(nbtn, true)">
                          {{ nbtn }}
                        </v-btn>
                      </template>
                      <span>{{ getMsg(nbtn, false) }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Fim Botões msgs prontas -->
              <v-col cols="12" md="11">
                <v-textarea outlined name="descricao" label="Descrição" v-model="reativacao.descricao"
                  v-validate="'required'" :rules="[(v) => !!v || 'Preenchimento Obrigatório!']" required rows="4">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="manifesto = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="saveManifesto()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim manifesto -->

    <!-- Inicio Rastreamento -->
    <v-card outlined style="padding:15px 15px 0px;" class="mt-0">
      <v-row>
        <template v-if="this.cod_transporta == 1">
          <v-col cols="12" md="4">
            <template v-if="!this.status_reclamacao">
              <v-select dense label="Motivos para abrir Reclamação" :items="itensReclamacao"
                prepend-icon="fas fa-bullhorn" v-model="reclamacao.id" item-text="fullmotivos" item-value="id"
                outline color="#018656"></v-select>
            </template>
            <template v-else-if="consulta.habilita">
              <v-text-field dense outlined label="Protocolo de reclamação" type="text" autocomplete="off"
                v-model="objeto.protocolo_reclamacao" :disabled="consulta.habilita" clearable>
              </v-text-field>
            </template>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn v-if="!this.status_reclamacao" title color="#018656" @click="criarReclamacao()">
              <v-icon left>fas fa-save</v-icon>
              Enviar
            </v-btn>
          </v-col>

          <v-col cols="12" md="3">
            <template v-if="this.status_reclamacao && !consulta.habilita">
              <v-text-field dense outlined label="Protocolo de reclamação" type="text" autocomplete="off"
                v-model="objeto.protocolo_reclamacao" clearable append-icon="fas fa-save"
                @click:append="salvarProtocoloReclamacao(objeto.protocolo_reclamacao)">
              </v-text-field>
            </template>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" md="4">
            <v-text-field dense outlined label="Abrir Chamado" type="text" autocomplete="off" v-model="chamado" clearable
              :disabled="true">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
          </v-col>
        </template>

        <v-col cols="12" md="2">
          <v-text-field :autofocus="showNro()" dense outlined label="Nº rastreio" type="text" autocomplete="off"
            v-model="consulta.item.numero_rastreio" :readonly="showNro() ? false : true" color="#018656">
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined style="margin-top: 10px;">
      <v-divider></v-divider>
      <v-card-text style="color: black;" class="pb-0">
      <v-row dense class="pb-0">
        <v-col class="d-flex">
          <fieldset style="padding-bottom: 15px;">
            <legend style="margin-left: 10px; padding-inline: 6px;">
              <b>Destinatário</b>
              <v-btn style="height: auto;" @click="overlay = !overlay" icon>
                <v-icon small>{{ overlay ? 'fas fa-eye' : 'fas fa-eye-slash' }}</v-icon>
              </v-btn>
            </legend>
            <v-row style="padding-left: 5px;">
              <v-col cols="12" style="padding-bottom: 0px">
                <b>Nome:</b> {{ detalhes.para.nome }}
              </v-col>
              <v-col cols="12" class="pb-0 pt-0 pr-4" style="white-space: nowrap;">
                <span v-if="!overlay"><b>Rua:</b> <input type="password" disabled v-model="detalhes.para.rua"></span>

                <span v-else><b>Rua:</b> {{ detalhes.para.rua }}</span>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <b>Bairro:</b> {{ detalhes.para.bairro }}
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <b>Cidade:</b> {{ detalhes.para.cidade }}
              </v-col>

              <v-col v-if="showCEP()" cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <b>Cep:</b> <input autofocus type="text" size="6" name="cep_dest" id="cep_dest" required
                  v-model="detalhes.para.cep" style="outline-style: auto; padding: 0px 4px; 
                  outline-color: rgba(0,0,0,100%);"/>
              </v-col>
              <v-col v-else cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <b>Cep:</b> {{ detalhes.para.cep }}
              </v-col>

              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <span v-if="!overlay"><b>Telefone:</b> <input type="password" disabled v-model="detalhes.para.telefone"></span>

                <span v-else><b>Telefone:</b> {{ detalhes.para.telefone }}</span>
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
        <v-col class="d-flex">
          <fieldset style="padding-bottom: 15px;">
            <legend style="margin-left: 10px; padding-inline: 6px;">
              <b>Envio</b>
            </legend>
            <v-row style="padding-left: 5px;">
              <v-col cols="12" style="padding-bottom: 0px">
                <b>Data postagem:</b> {{ detalhes.enviado.data_postagem }}
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px">
                <b>Data considerada:</b> {{ detalhes.enviado.data_considerada }}
              </v-col>
              <v-col class="py-0" cols="12" style="white-space: nowrap;">
                <span v-if="showCEP()"><b>Cep Origem:</b> <input autofocus type="text" size="6" name="cep_origem" id="cep_origem" required
                  v-model="detalhes.enviado.cep" style="outline-style: auto; padding: 0px 4px;
                  outline-color: rgba(0,0,0,100%);"/>
                </span>

                <span v-else><b>Cep Origem:</b> {{ detalhes.enviado.cep }}</span>

                <span class="ml-3"><b>Cidade Origem:</b> {{ detalhes.enviado.cidade }}</span>
              </v-col>
              
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; display: flex;">
                <b>NFE:</b> <span style="padding-inline: 5px;">{{ detalhes.numero_danfe }}</span>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0" style="white-space: nowrap; padding-right: 14px;">
                <span><b>Nº Pedido ERP: </b>{{ detalhes.numero_pedido }}</span>
                <span class="ml-3"><b>Nº Pedido Canal Venda: </b>{{ detalhes.order_id }}</span>
              </v-col>

              <v-col cols="12" class="py-0" style="white-space: nowrap;">
                <span><b>Canal de Venda:</b> {{ detalhes.canal_venda }}</span>
                <span class="ml-3"><b>Sub Canal:</b> {{ detalhes.sub_canal }} ({{ detalhes.id_canal_venda }})</span>
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
        <v-col class="d-flex">
          <fieldset style="padding-bottom: 15px;">
            <legend style="margin-left: 10px; padding-inline: 6px;">
              <b>Entrega</b>
            </legend>
            <v-row style="padding-left: 20px; padding-top: 10px;">
              <v-col cols="12" class="py-0 pl-0">
                <b>Transportadora:</b> {{ detalhes.transportadora }}
              </v-col>

              <v-col cols="12" class="pa-0 pr-3" style="white-space: nowrap;">
                <b>Serviço:</b> {{ detalhes.servico }}
                <span v-if="showCEP()" class="ml-3">
                  <b>Código Serviço:</b> <input autofocus type="text" size="2" name="cod_serv" id="cod_serv" required
                  v-model="detalhes.cod_servico" style="outline-style: auto; padding: 0px 4px; outline-color: rgba(0,0,0,100%);"/>
                </span>

                <span v-else class="ml-3">
                  <b>Código Serviço:</b> {{ detalhes.cod_servico }}
                </span>
              </v-col>

              <v-col cols="12" class="pa-0" style="white-space: nowrap; padding-right: 14px !important;">
                <b>Data Prevista:</b> {{ detalhes.previsao_de_entrega.data }}
                <span class="ml-3"><b>Entregue em:</b> {{ detalhes.entregue_em.data }}</span>
              </v-col>

              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; ">
                <b>Em domicilio:</b> {{ detalhes.previsao_de_entrega.em_domicilio }}
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; ">
                <b>Tipo Objeto:</b> {{ detalhes.tipo_objeto }}
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
        <v-col class="d-flex" style="position: relative;">
          <fieldset style="padding-bottom: 15px; width: 100%;">
            <legend style="margin-left: 10px; padding-inline: 6px;">
              <b>Ultima Movimentação</b>
            </legend>
            <v-row style="padding-left: 5px;">
              <v-col cols="12" style="padding-bottom: 0px;">
                <b>Data:</b> {{ detalhes.dt_ultima_posicao }}
              </v-col>
              <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                <b>Motivo:</b> {{ detalhes.ultima_posicao }}
              </v-col>
              <v-col cols="12"></v-col>
              <v-col v-show="showBtn() || showCEP()" cols="12" style="display: flex; justify-content: end;
              position: absolute; right: 0; bottom: 0;">
                <v-btn depressed color="#018656" class="btnSalvar" @click="save()">
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </fieldset>
        </v-col>
      </v-row>
      </v-card-text>
      
      <v-row v-if="this.status_reclamacao > 1">
        <v-col cols="12">
          <b>Ações objeto reclamado</b>
          <v-row align="center" justify="space-around">
            <v-btn v-if="this.status_reclamacao > 1 && this.etapas_reclamacao == 1" color="orange" depressed rounded
              @click="btnReReclama()">
              Reclamar Novamente
            </v-btn>
            <v-btn v-if="consulta.habilita" color="warning" depressed rounded
              @click="btnReAtivacao(consulta.item.protocolo_retorno)">
              REATIVAÇÂO
            </v-btn>
            <v-btn color="error" depressed rounded @click="btnReclama(2)">
              ENCERRAMENTO
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <!-- Inicio Ocorrencias -->
      <v-row>
        <v-col cols="12">
          <Ocorrencias v-if="consulta.detalhes && showOcor" :nroobjeto="consulta.item.codigo" :statusIn="status.items"
            :ocorIn="ocor.items" :encerrar="encerrar2" />
        </v-col>
      </v-row>
      <!-- Fim Ocorrencias -->
      <!-- Inicio Reclamacao -->
      <v-row v-if="this.status_reclamacao">
        <v-col cols="12" class="px-4">
          <v-data-table dense :headers="recl.cabecalho" :items="this.recl.items" hide-default-footer class="elevation-1">
            <template 
            v-for="header in recl.cabecalho.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ header, value }">
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Fim Reclamacao -->
    </v-card>

    <v-card outlined style="margin-top: 10px;">
      <v-card-text style="padding-top: 5px;">
        <v-row style="width: 100%; position: relative;">
          <v-col cols="12" class="pl-0 pr-0">
            <v-tabs style="position: relative; left: 20px;" color="#fff" v-model="tbRec" slider-color="red" flat
              active-class="active">
              <v-tab v-for="item in itRec" :key="item.value">
                {{ item.tab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tbRec">
              <v-tab-item v-for="item in itRec" :key="item.value">
                <component v-if="consulta.detalhes" :nroobjeto="getParam(item.value)" v-bind:is="item.content">
                </component>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim Rastreamento -->
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../Template.vue";
import Rastreio from "./frmrastreio.vue";
import Interacoes from "./frminteracoes.vue";
import Notificacoes from "./frmnotificacoes.vue";
import Ocorrencias from "./frmocorrencias.vue";

export default {
  name: "Rastreamento",
  components: {
    Template,
    Rastreio,
    Interacoes,
    Notificacoes,
    Ocorrencias,
  },
  props: {
    consulta: { type: Object },
    encerrar: { type: Boolean },
    route: { type: String },
  },
  data: () => ({
    showOcor: false,
    analisar: 0,
    overlay: false,
    qtd_rastreios: [],
    modal: {
      prod: false,
      cotacao: false,
      embalamento: false,
      status: false,
      RequisicaoCotacao: false,
      RequisicaoEmbalamento: false,
    },
    conferencia: {
      detalhes: {
        orderID: null,
        nrmpedido: null,
        transportadora: null,
        servico: null,
        subcanal: null,
        base: null,
      },
      numeroRastreio: null,
      numeroCTE: 0,
      diffCTETabR$: 0.0,
      valorAudCTETab: 0.0,
      diffValorEmbarqueXCTE: 0.0,
      diffPesoEmbarqueXCTE: 0.0,
      valorPagoTransp: 0.0,
      pesoCTE: 0.0,
      valorNFCTE: 0.0,
      lucroAposEmbarque: 0.0,
      diffValorVendaEmbarque: 0.0,
      diffPesoVendaEmbarque: 0.0,
      valorFreteEmbarcado: 0.0,
      pesoEmbarque: 0.0,
      valorNFEmbarque: 0.0,
      diffVendaAud: 0.0,
      taxa: 0.0,
      valorFreteRecebido: 0.0,
      lucroVenda: 0,
      acrescimos: 0.0,
      difal: 0.0,
      valorCobradoTabela: 0.0,
      valorFreteCotacaoVenda: 0.0,
      pesoVenda: 0.0,
      valorNFVenda: 0.0,
      diffPesoCTExCTERed: 0.0,
      lucroFinal: 0,
      difalpercent: null,
      lucroVendapercent: null,
      acrescimopercent: null,
      lucroEmbarquePercent: null,
      lucroFinalPercent: null,
      diffPesoVendaEmbarquePercent: null,
      diffValorVendaEmbarquePercent: null,
      diffPesoEmbarqueXCTEPercent: null,
      diffValorEmbarqueXCTEPercent: null,
      diffCTETabR$Percent: null,
      diffPesoEmbarqueXCTERedPercent: null,
      diffValorEmbarqueXCTERedPercent: null,
      diffCTETabR$RedPercent: null,
      diffPesoCTExCTERedPercent: null,
      diffVendaAudPercent: null,
      valorAudCTETabPercent: null,
      valorAudCTETabRedPercent: null,
      diffCTETabR: 0,
      valorAudCTETabRed: 0,
      numeroCTERed: 0,
      diffValorEmbarqueXCTERed: 0,
      diffPesoEmbarqueXCTERed: 0,
      valorPagoTranspRed: 0,
      pesoCTERed: 0,
      valorNFCTERed: 0,
      diffCTETabR$Red: 0,
    },
    codigo_empresa: '',
    cod_transporta: '',
    chamado: '',
    dt_manifesto: moment(new Date()).format("YYYY-MM-DD"),
    dateStartMan: moment(new Date()).format("YYYY-MM-DD"),
    email_correios: '',
    tbRec: 0,
    itRec: [
      { tab: "Interações", content: "Interacoes", value: 1 },
      { tab: "Rastreio", content: "Rastreio", value: 2 },
      { tab: "Notificações", content: "Notificacoes", value: 3 },
    ],
    rec: [
      { name: "ABRIR RECLAMAÇÃO", url: "" },
      { name: "RE ATIVAR RECLAMAÇÃO", url: "" },
      { name: "ENCERRAR RECLAMAÇÃO", url: "reclamacoes-encerramento" },
      { name: "RECLAMAR NOVAMENTE", url: "reclamacoes-again" },
    ],
    msgDetails: [
      { msg: "Somente Compatibilidade" },
      { msg: "Prezados Correios , destinatário alega que não recebeu a encomenda por favor informar o nome do recebedor da encomenda ou indenizar por extravio" },
      { msg: "Cep de destino consta no calculador de preços e prazos com entrega domiciliar no ato da contratação do serviço, sendo que no contrato de prestação de serviço teria tentativa de entrega que não ocorreu nesse objeto, assim solicitamos a indenização prevista em contrato de 15% do valor do serviço" },
    ],
    habilita: false,
    grid: {
      produtos: {
        cabecalho: [
          { text: "Código", value: "codigo" },
          { text: "Quantidade", value: "quantidade", align: "right" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        items: [],
      },
      cotacao: {
        cabecalho: [
          { text: "Código", value: "sku" },
          { text: "Quantidade", value: "quantity", align: "right" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoCotacao: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo Transp", value: "prazo transporte", align: "right" },
          { text: "Aliq. acresc. token", value: "aliquota_acrescimo_token", align: "right" },
          { text: "Base acresc. token", value: "base_acrescimo_token", align: "right" },
          { text: "Valor acresc. token", value: "valor_acrescimo_token", align: "right" },
          { text: "Base difal", value: "base_difal", align: "right" },
          { text: "Valor difal", value: "valor_difal", align: "right" },
          { text: "Valor produto difal", value: "valor_produto_difal", align: "right" },
          { text: "Aliquota difal", value: "aliquota_difal", align: "right" },
          { text: "Tipo difal", value: "tipo_difal" },
          { text: "Base calc. adicional", value: "base_calc_adicional", align: "right" },
        ],
        items: [],
      },
      produtosEmbalamento: {
        cabecalho: [
          { text: "Quantidade", value: "quantidade" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        cabecalhoUS: [
          { text: "Quantidade", value: "quantity" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoEmbalamento: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Valor frete", value: "valor_frete", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo transporte", value: "prazo_transporte", align: "right" }
        ],
        items: [],
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
    recl: {
      cabecalho: [
        { text: "Data Reclamação", value: "data_reclamacao", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : "Sem Retorno"), },
        { text: "Motivo", value: "motivo", sortable: false },
        { text: "Inclusão", value: "modo", sortable: false },
      ],
      items: [],
    },
    ocor: {
      cabecalho: [
        { text: "Tentativa Entrega", value: "tentativa_entrega", sortable: false },
        { text: "Obj. Não Encontrado", value: "objeto_nao_encontrado", sortable: false },
        { text: "Extravio", value: "extravio", sortable: false },
        { text: "Roubo", value: "roubo", sortable: false },
        { text: "Falha Remetente", value: "falha_remetente", sortable: false },
        { text: "Falha Fiscal", value: "falha_fiscal", sortable: false },
        { text: "Atraso", value: "atraso", sortable: false },
        { text: "Aguardando Retirada", value: "aguardando_retirada", sortable: false },
        { text: "Avaria", value: "avaria", sortable: false },
        { text: "Alerta", value: "alerta", sortable: false },
        { text: "Tratativa Pendente", value: "tratativa_pendente", sortable: false },
        { text: "", value: "controls", sortable: false },
      ],
      items: [],
      carregando: false,
      paginaAtual: 1,
    },
    status: {
      cabecalho: [
        { text: "Status", value: "status", sortable: false },
        { text: "Cod. Situação", value: "cod_situacao", sortable: false },
        { text: "Devovulção", value: "devolvido", sortable: false },
      ],
      items: [],
      carregando: false,
      paginaAtual: 1,
    },
    detalhes: {
      enviado: { data_postagem: "", data_considerada: "", cep: "", cidade: "", },
      para: { nome: "", cep: "", cidade: "", bairro: "", rua: "", },
      previsao_de_entrega: { data: "", em_domicilio: "", },
      entregue_em: { data: "", },
      numero_rastreio: "",
      numero_danfe: "",
      order_id: "",
      cod_servico: '',
      tipo_objeto: '',
      transportadora: '',
    },
    objeto: {
      numero_rastreio: "",
      rastreio: "",
      habilita: false,
      protocolo_reclamacao: "",
    },
    reclamacao: {
      id: "",
      codigo: "",
      numero_rastreio: "",
      cod_empresa: "",
    },
    reclamacao_motivo: "",
    status_reclamacao: false,
    etapas_reclamacao: false,
    manifesto: false,
    mod_dt_manifesto: false,
    reativacao: {
      dt_manifesto: "",
      tipo: "",
      codigo: "",
      descricao: "",
      cod_empresa: "",
      numero_rastreio: "",
      numero_protocolo: "",
    },
    itensReclamacao: [],
    itensTipo: [{ id: "R", descricao: "Reclamação" },
    { id: "I", descricao: "Ressarcimento" },
    ],
    sendComplaint: false,
    url: { urlconsulta: false, token: '', },
    img: { imgconsulta: false, svgImg: '', },
    btnImage: false,
    encerrar2: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    this.encerrar2 = this.encerrar;
  },
  async mounted() {
    this.tbRec = this.consulta.aba_rastreio == true ? 1 : 0;
    await this.carregarRastreio.call(this, this.consulta.item.numero_rastreio);
    await this.carregarDetalhes.call(this, this.consulta.item);
    await this.carregarMotivosReclamacao();
  },
  async beforeMount() {
    await this.carregaDetalhesEmpresa(this.codigo_empresa)
    this.tabItems(this.consulta.aba_rastreio ? 1 : 0);
  },
  computed: {
    formatDate() {
      return this.reativacao.dt_manifesto ? moment(this.reativacao.dt_manifesto).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    showBtn(){
      if (this.detalhes.transportadora == "CORREIOS") {
        if(this.detalhes.ultima_posicao == "SRO-020: Objeto n\u00e3o encontrado na base de dados dos Correios."
        || this.route == "Aguardando Envio"){
          return true;
        }

        if(this.detalhes.falha_remetente > 0 || this.route == "Aguardando Envio") {
          return true;
        }
      }
    },

    showCEP(){
      if(this.detalhes.transportadora == "CORREIOS") {
        if(this.detalhes.falha_remetente > 0 || this.route == "Aguardando Envio") {
          return true;
        }
      }
    },

    showNro(){
      if(this.qtd_rastreios.length > 0){
        if(this.detalhes.ultima_posicao == "SRO-020: Objeto n\u00e3o encontrado na base de dados dos Correios."
        || (this.route == "Aguardando Envio" && this.qtd_rastreios[0].ras_codigo == null)){
          return true;
        }
      }
    },

    getParam(value) {
      if (value == 1) {
        return { numero_pi: this.consulta.item.codigo, numero_rastreio: this.consulta.item.numero_rastreio, cod_empresa: this.codigo_empresa };
      }
      else{
        return this.consulta.item.numero_rastreio;
      }
    },
    getMsg(nroOpc, retMsg) {
      let msgPut = this.msgDetails[nroOpc].msg;
      if (retMsg) {
        this.reativacao.descricao = msgPut;
      } else {
        return msgPut;
      }
    },
    tabItems(tab) {
      this.tbRec = tab;
    },
    async urlCop() {
      await navigator.clipboard.writeText(this.url.token);
      this.url.urlconsulta = false;
    },
    async urlConsulta() {
      let url = `${baseApiUrl}/objeto-url`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${this.consulta.item.numero_rastreio}`;
      url += `&simpleBagq=Simple`;
      url += `&xspaceBagq=${this.consulta.item.codigo}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.url.token = `https://rastreieja.com.br/${response.data.dados.id}`;
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    async imgConsulta() {
      let url = `${baseApiUrl}/objeto-img`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${this.consulta.item.numero_rastreio}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.img.svgImg = `${response.data.dados}`;
            this.img.imgconsulta = true;
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: `${response.data.message}`,
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });

          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    async carregarMotivosReclamacao() {
      let url = `${baseApiUrl}/motivos-reclamacao`;
      axios.get(url).then((res) => {
        this.itensReclamacao = res.data.motivos;
      });
    },
    async carregaDetalhesEmpresa(empresa) {
      let url = `${baseApiUrl}/empresas-detalhes?codigo=${empresa}&type=detalhes`;
      axios
        .get(url)
        .then((res) => {
          this.email_correios = res.data.empresas.data[0].emailcorreios == null ? "" : res.data.empresas.data[0].emailcorreios;
        });
    },
    async getTokenApi() {
      await axios
        .get(
          `${baseApiUrlPersys}/chaves/${this.codigo_empresa}`
        )
        .then((res) => {
          this.tokenApi = res.data.tokenApi
        });
    },
    async carregarDetalhesObjeto(itemDetails) {
      this.grid.detalhes.carregando = true;
      let url = `${baseApiUrl}/detalhes-ocorrencias`;
      url += `?numero_rastreio=${itemDetails.numero_rastreio}`;
      url += `&cod_empresa=${itemDetails.cod_empresa}`;
      await axios
        .get(url)
        .then((res) => {
          this.detalhes.enviado.data_postagem = moment(res.data.dados[0].data_postagem).format("DD/MM/yyyy");
          this.detalhes.enviado.data_considerada = moment(res.data.dados[0].data_considerada).format("DD/MM/yyyy");
          this.detalhes.enviado.cep = res.data.dados[0].remetente_cep;
          this.detalhes.para.nome = res.data.dados[0].destinatario_nome;
          this.detalhes.para.cep = res.data.dados[0].destinatario_cep;

          if(res.data.dados[0].destinatario_celular != null && res.data.dados[0].destinatario_celular != ""){
            res.data.dados[0].destinatario_celular = res.data.dados[0].destinatario_celular.replace(/[() -]/g, '');
            this.detalhes.para.telefone = "("+ res.data.dados[0].destinatario_celular.slice(0, 2) +
            ") "+ res.data.dados[0].destinatario_celular.slice(2,7) + "-" + res.data.dados[0].destinatario_celular.slice(7);
          }else{
            this.detalhes.para.telefone = "";
          }

          this.detalhes.para.cidade = res.data.dados[0].destinatario_cidade + "/" + res.data.dados[0].destinatario_estado;
          this.detalhes.para.bairro = res.data.dados[0].destinatario_bairro;
          this.detalhes.para.rua = res.data.dados[0].destinatario_rua + ", " + res.data.dados[0].destinatario_numero;
          this.detalhes.previsao_de_entrega.data = moment(res.data.dados[0].data_prevista).format("DD/MM/yyyy");
          this.detalhes.previsao_de_entrega.em_domicilio = res.data.dados[0].entrega_domicilio;
          this.detalhes.entregue_em.data = res.data.dados[0].data_entrega != null ? moment(res.data.dados[0].data_entrega).format("DD/MM/yyyy") : "Não entregue";
          this.detalhes.servico = res.data.dados[0].servico_entrega;
          this.detalhes.cod_servico = res.data.dados[0].cod_servico;
          this.detalhes.tipo_objeto = res.data.dados[0].tipo_objeto;
          this.detalhes.transportadora = itemDetails.transportadora;
          this.detalhes.canal_venda = res.data.dados[0].canal_venda;
          this.detalhes.sub_canal = res.data.dados[0].sub_canal;
          this.detalhes.id_canal_venda = res.data.dados[0].idcanal_vendas;
          this.detalhes.dt_ultima_posicao = moment(res.data.dados[0].data_ultima_posicao).format("DD/MM/YYYY");
          this.detalhes.ultima_posicao = res.data.dados[0].ultima_posicao;
          this.detalhes.falha_remetente = res.data.dados[0].falha_remetente;

          this.status.items = [{
            "status": res.data.dados[0].status,
            "cod_situacao": res.data.dados[0].cod_situacao,
            "time_line": res.data.dados[0].time_line,
            "devolvido": res.data.dados[0].devolvido,
            "analisar": res.data.dados[0].analisar,
          }];

          this.ocor.items = [{
            "Atrasado": res.data.dados[0].atrasado,
            "Devolvido": res.data.dados[0].devolvido,
            "Tentativa Entrega": res.data.dados[0].tentativa_entrega,
            "Extravio": res.data.dados[0].extravio,
            "Roubo": res.data.dados[0].roubo,
            "Falha Remetente": res.data.dados[0].falha_remetente,
            "Falha Fiscal": res.data.dados[0].falha_fiscal,
            "Aguardando Retirada": res.data.dados[0].aguardando_retirada,
            "Avaria": Number(res.data.dados[0].avaria),
            "Alerta": Number(res.data.dados[0].alerta),
            "Pos-Entrega": res.data.dados[0].pos_entrega
          }];

          this.showOcor = true;

          this.objeto.numero_rastreio = itemDetails.numero_rastreio;
          this.objeto.habilita = Number(this.consulta.item.protocolo_retorno) > 0;
          this.objeto.protocolo_reclamacao = this.consulta.item.protocolo_retorno;
          this.status_reclamacao = res.data.dados[0].reclamacao;
          this.etapas_reclamacao = res.data.dados[0].status_reclamacao;
          this.detalhes.numero_danfe = res.data.dados[0].numero_danfe;
          this.detalhes.numero_pedido = res.data.dados[0].numero_pedido;
          this.detalhes.order_id = res.data.dados[0].pedido_canal_venda;
          this.cod_transporta = res.data.dados[0].cod_transportadora;

          this.btnImage = res.data.dados[0].cod_transportadora == 1 && res.data.dados[0].cod_situacao == 3 ? true : false;
        })
        .finally(() => {
          this.grid.detalhes.carregando = false, this.buscaCEP()
        });
    },

    async carregarRastreio(numero_rastreio) {
      let url = `${baseApiUrl}/rastreios?cod_empresa=${this.codigo_empresa}&numero_rastreio=${numero_rastreio}`;

      await axios
        .get(url)
          .then((res) => {
            this.qtd_rastreios = res.data.dados;
          }
        );
    },

    async buscaCEP(){
      if(this.detalhes.enviado.cep != null && this.detalhes.enviado.cep != ""){
        let url = `${baseApiUrl}/busca-cep?cep=${this.detalhes.enviado.cep.toString().replace('-', '')}`;
  
        await axios
          .get(url)
            .then((res) => {
              this.detalhes.enviado.cidade = res.data[0].cidade + "/" +res.data[0].uf;
            })
            .catch(() => {
              this.detalhes.enviado.cidade = null;
            })
      }else{
        this.detalhes.enviado.cidade = "";
      }
    },

    async save(){
      let url = `${baseApiUrl}/objetos`;

      await axios
        .post(url, {
          cep_de: this.detalhes.enviado.cep.toString(),
          cep_para: this.detalhes.para.cep.toString(),
          cod_servico: this.detalhes.cod_servico.toString(),
          numero_rastreio: this.consulta.item.numero_rastreio.toString()
        })
        .then(() => {
          showSuccess("Dados atualizados com sucesso!");
        })
        .catch(() => {
          showError("Não foi possível salvar as alterações");
        })
    },

    async 'carregarConferencia'(item) {
      this.conferencia.detalhes.transportadora = item.transportadora;
    },

    async carregarDetalhes(item) {
      await this.carregarDetalhesObjeto.call(this, item);
      this.carregarConferencia.call(this, item);
      this.recl.items = [{
        "data_reclamacao": this.consulta.item.data_reclamacao,
        "motivo": this.consulta.item.motivo,
        "modo": this.consulta.item.modo
      }];
    },
    btnReReclama() {
      this.$swal({
        title: `${this.rec[3].name} para o objeto ${this.consulta.item.numero_rastreio}`,
        text: `Confirmar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (`${this.rec[3].url}`) {
              let objetoSend = { numero_rastreio: `${this.consulta.item.numero_rastreio}`, codigo: `${this.status_reclamacao}`, cod_empresa: `${this.codigo_empresa}` };
              await this.enviarReclamacao(`${this.rec[3].url}`, objetoSend)
            } else {
              this.$swal({
                title: 'Ops!!!',
                text: 'Em breve será liberado!!!',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              })
            }
          }
        });
    },
    btnReAtivacao(protocoloPi) {
      this.$swal({
        title: 'REATIVAÇÃO DE PROTOCOLO',
        text: `Reativar o protocolo ${protocoloPi} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            let motivo = this.consulta.item.motivo.substr(0, 4);
            this.reativacao = { dt_manifesto: this.dt_manifesto, tipo: "", codigo: this.status_reclamacao, descricao: "", cod_empresa: "", numero_rastreio: "", numero_protocolo: "", };
            this.itensReclamacao.forEach(element => {
              if (element.codigo == motivo.trim()) {
                this.reativacao.codigo = element.id;
              }
            });
            this.manifesto = true;
          }
        });
    },
    criarReclamacao() {
      this.reclamacao_motivo = "";
      if (this.reclamacao.id) {
        this.itensReclamacao.forEach(element => {
          if (element.id == this.reclamacao.id) {
            this.reclamacao.codigo = element.codigo;
            this.reclamacao_motivo = element.motivo;
          }
        });
        this.reclamacao.numero_rastreio = this.consulta.item.numero_rastreio;
        this.sendComplaint = true;
      } else {
        this.$swal({
          title: 'ATENÇÃO!!!',
          text: 'OBRIGATÓRIO SELECIONAR O MOTIVO DA RECLAMAÇÃO!',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        })
      }
    },
    btnReclama(varType, varOpc) {
      this.$swal({
        title: `${this.rec[varType].name}`,
        text: `Confirmar ação ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (`${this.rec[varType].url}`) {
              let objetoSend = { numero_rastreio: `${this.consulta.item.numero_rastreio}`, codigo: '', cod_empresa: `${this.codigo_empresa}` };
              await this.enviarReclamacao(`${this.rec[varType].url}`, objetoSend)
            } else {
              this.$swal({
                title: 'Ops!!!',
                text: 'Em breve será liberado!!!',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              })
            }
          }
        });
    },
    async enviarReclamacao(uri, data) {
      let url = `${baseApiUrl}/${uri}`;
      url += `?cod_empresa=${data.cod_empresa}`;
      url += `&codigo=${data.codigo}`;
      url += `&numero_rastreio=${data.numero_rastreio}`;
      let retReclamacao = false;
      await axios
        .get(url)
        .then((response) => {
          retReclamacao = response.data.retorno;
          if (response.data.retorno) {
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Solicitação concluída.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.grid.detalhes.carregando = false;
          if (retReclamacao) {
            this.consulta.detalhes = false;
            this.$emit('detclose', true);
          }
        });
    },
    async saveManifesto() {
      this.reativacao.cod_empresa = this.codigo_empresa;
      this.reativacao.numero_rastreio = this.consulta.item.numero_rastreio;
      this.reativacao.numero_protocolo = this.consulta.item.protocolo_retorno;
      this.reativacao.cod_recno = this.consulta.item.codigo;
      if (!!this.reativacao.descricao && !!this.reativacao.dt_manifesto && !!this.reativacao.tipo && !!this.reativacao.codigo) {
        await axios
          .post(`${baseApiUrl}/manifesto-reativar`, this.reativacao)
          .then((response) => {
            if (response.data.retorno) {
              this.$swal({
                title: 'Sucesso!!!',
                text: 'Solicitação concluída.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            }
            else {
              this.$swal({
                title: 'Que pena!!!',
                text: `${response.data.mensagem}`,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            }
          })
          .catch((error) => {
            this.$swal({
              title: 'Ops!!!',
              text: `${error.data.mensagem}`,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
          .finally(() => {
            this.manifesto = false
          });
      } else {
        this.$swal({
          title: 'Que pena!!!',
          text: 'Existem campos em branco, verifique!',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      }
    },
    salvarProtocoloReclamacao(nroPi) {
      if (nroPi != null) {
        this.$swal({
          title: 'SALVAR PROTOCOLO',
          text: `Salvar o protocolo ${nroPi} para o objeto ${this.consulta.item.numero_rastreio} ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              let retReclamacao = false;
              this.grid.detalhes.carregando = true;
              axios
                .post(`${baseApiUrl}/objetos`, {
                  cod_empresa: this.codigo_empresa,
                  codigo: this.consulta.item.codigo,
                  numero_rastreio: this.consulta.item.numero_rastreio,
                  protocolo_reclamacao: this.objeto.protocolo_reclamacao,
                })
                .then((response) => {
                  retReclamacao = response.data.retorno;
                  if (response.data.retorno) {
                    this.$swal({
                      title: 'Sucesso!!!',
                      text: 'Solicitação concluída.',
                      icon: 'success',
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#4BB543',
                      allowOutsideClick: true,
                    });
                  }
                })
                .catch((error) => {
                  this.$swal({
                    title: 'QUE PENA!!!',
                    text: 'A solicitação não foi concluída.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#4BB543',
                    allowOutsideClick: true,
                  });
                })
                .finally(() => {
                  this.grid.detalhes.carregando = false
                  if (retReclamacao) {
                    this.consulta.detalhes = false;
                    this.$emit('detclose', true);
                  }
                });
            }
          });
      } else {
        this.$swal({
          title: 'Ops!!!',
          text: 'Número PI Obrigatório!!!',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      }
    },
    salvarReclamacao() {
      this.grid.detalhes.carregando = true;
      let retReclamacao = false;
      axios
        .post(`${baseApiUrl}/reclamacao-send`, {
          cod_empresa: this.codigo_empresa,
          codigo: this.consulta.item.codigo,
          id: this.reclamacao.id,
          motivo: this.reclamacao.codigo,
          numero_rastreio: this.consulta.item.numero_rastreio,
        })
        .then((response) => {
          retReclamacao = response.data.retorno;
          if (response.data.retorno) {
            showSuccess(response.data.mensagem);
          } else {
            showError(response.data.mensagem);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          this.grid.detalhes.carregando = false
          if (retReclamacao) {
            this.consulta.detalhes = false;
            this.$emit('detclose', true);
          }
        });
    },
  },
}
</script>

<style>
.active {
  background-color: #018656;
  color: #fff;
}

.btnSalvar {
  color: #fff !important;
  position: relative;
  right: 10px;
}

.fieldStyle {
  height: 198px;
}
</style>