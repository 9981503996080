<template>
  <div>
    <v-dialog v-model="modal.prod" width="1200">
      <v-card>
        <v-card-title class="text-h7"
          style="color: white; justify-content: center; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          Produtos
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table dense :headers="this.grid.produtos.cabecalho" :items="this.grid.produtos.items"
            hide-default-footer class="elevation-1" item-key="codigo">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Final Produtos -->

    <!-- Inicio Cotacao -->
    <v-dialog v-model="modal.cotacao" width="1200">
      <v-card>
        <v-card-title class="text-h7" style="color: white; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          <v-spacer></v-spacer>
          <div style="margin-left: 150px">
            Cotação - CEP destino: - Token:
          </div>
          <!-- {{ dadosCotacao.cepDestino }} {{ dadosCotacao.token }}-->
          <v-spacer></v-spacer>
          <v-btn @click="modal.RequisicaoCotacao = true"
            style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table dense :headers="grid.cotacao.cabecalho" :items="grid.cotacao.items" hide-default-footer
            class="elevation-1" item-key="codigo">
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="text-h7"
          style="color: white; justify-content: center; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          Retorno Cotação
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table dense :headers="grid.retornoCotacao.cabecalho" :items="grid.retornoCotacao.items"
            hide-default-footer class="elevation-1" item-key="codigo">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Final Cotacao -->

    <!-- Inicio Embalamento -->
    <v-dialog v-model="modal.embalamento" width="1200">
      <v-card>
        <v-card-title class="text-h7" style="color: white; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          <v-spacer></v-spacer>
          <div style="margin-left: 150px">
            Embalamento - CEP destino: - Token:
          </div>
          <!-- {{ dadosEmbalamento.cepDestino }} {{ dadosEmbalamento.token }}-->
          <v-spacer></v-spacer>
          <v-btn @click="modalRequisicaoEmbalamento = true"
            style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table dense :headers="grid.produtosEmbalamento.cabecalhoUS"
            :items="grid.produtosEmbalamento.items" hide-default-footer class="elevation-1" item-key="codigo">
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="text-h7"
          style="color: white; justify-content: center; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          Retorno Embalamento
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table dense :headers="grid.retornoEmbalamento.cabecalho"
            :items="grid.retornoEmbalamento.items" hide-default-footer class="elevation-1" item-key="codigo">
            <template #item.Base="{ item }">
              <span style="text-decoration: none;" :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''">
                {{ item.Base }}
              </span>
            </template>

            <template #item.custo="{ item }">
              <span style="text-decoration: none;" :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''">
                {{ item.custo }}
              </span>
            </template>

            <template #item.valor_frete="{ item }">
              <span style="text-decoration: none;" :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''">
                {{ item.valor_frete }}
              </span>
            </template>

            <template #item.peso="{ item }">
              <span style="text-decoration: none;" :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''">
                {{ item.peso }}
              </span>
            </template>

            <template #item.prazo_transporte="{ item }">
              <span style="text-decoration: none;" :style="item.Base == 2672 ? 'color: green; font-weight: bold;' : ''">
                {{ item.prazo_transporte }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Final Embalamento -->

    <!-- Inicio Requisicao Embalamento -->
    <v-dialog v-model="modal.RequisicaoEmbalamento" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Embalamento
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.RequisicaoEmbalamento = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonEmbalamento" style="margin-top: 20px !important">
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copiarJsonEmbalamento()">
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Final Requisicao Embalamento -->

    <!-- Inicio Inf. Adicionais -->
    <v-dialog v-model="modal.status" width="1200">
      <v-card>
        <v-card-title class="text-h7"
          style="color: white; justify-content: center; height: 35px; padding: 0; background-color: rgb(1, 134, 86);">
          Informações Adicionais
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" style="padding-bottom: 0px">
              <b>Número do pedido:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Código pedido ERP:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Status:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Emissão nota:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Série nota:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Número nota:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Chave nfe:</b>
            </v-col>
            <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
              <b>Código rastreio:</b>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Final Inf. Adicionais -->

    <!-- Inicio modal Requisicao -->
    <v-dialog v-model="modal.RequisicaoCotacao" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Cotação
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.RequisicaoCotacao = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonCotacao" style="margin-top: 20px !important">
          <!-- {{ json.cotacao }} -->
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copiarJsonCotacao()">
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Final modal Requisicao -->

    <v-toolbar color="#018454" outlined rounded dense style="width: 100vw;" flat>
      <v-row dense align="center" class="pa-2">
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Order ID:</v-card-text>
          <v-card-text class="cardTextStyle py-0">{{ detalhes.order_id }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Pedido:</v-card-text>
          <v-card-text class="cardTextStyle py-0">{{ detalhes.numero_pedido }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Transportadora:</v-card-text>
          <v-card-text :title="detalhes.transportadora_nome" class="cardTextStyle py-0 text-truncate">{{ detalhes.transportadora_nome }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Serviço:</v-card-text>
          <v-card-text class="cardTextStyle py-0">{{ detalhes.servico }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Subcanal:</v-card-text>
          <v-card-text class="cardTextStyle py-0">{{ detalhes.subcanal }}</v-card-text>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="d-grid justify-center">
          <v-card-text class="cardTextStyle py-0">Base:</v-card-text>
          <v-card-text class="cardTextStyle py-0">{{ detalhes.base_transportadora }}</v-card-text>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- Inicio Conferencia -->
      <v-row class="mt-0">
        <v-col xs="12" sm="12" md="6" lg="3" xl="3">
          <v-card class="pa-2" style="height: 100%; width: 100%;">
            <!-- Início Field Venda -->
            <fieldset style="background-color: white;">
              <legend class="legendHeaderStyle">Venda</legend>
              <fieldset style="margin-bottom: 0px; margin-left: 10px; margin-right: 10px;">
                <legend class="legendStyle">Base de Cálculo</legend>
                <v-row dense class="pa-2">
                  <v-col cols="12" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly class="py-0" outlined hide-details dense v-model="conferencia.venda.valor_nf" label="Valor NF"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field color="#018454" readonly outlined class="py-0" hide-details dense v-model="conferencia.venda.peso" label="Peso"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <fieldset style="margin-bottom: 5px; margin-left: 10px; margin-right: 10px;">
                <legend class="legendStyle">Detalhamento</legend>
                <v-row dense class="pa-2">
                  <v-col cols="12" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.valor_frete"
                      label="Valor Frete Cotação Venda"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.valor_cobrado" label="Valor Custo Tabela"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" md="8" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.acrescimo" label="Acréscimos"></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.porcentagem_base" label="Base %"></v-text-field>
                  </v-col>
                  <v-col cols="8" md="8" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.difal" label="Difal"></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.porcentagem_difal" label="Base %"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly hide-details outlined dense v-model="conferencia.venda.taxa" label="Taxa">
                    </v-text-field>
                  </v-col>

                  <v-col cols="8" md="8" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly outlined hide-details dense
                      v-model="conferencia.venda.desconto_frete" label="Desconto Frete">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly outlined hide-details dense
                      v-model="conferencia.venda.porcentagem_desconto" label="Base %">
                    </v-text-field>
                  </v-col>

                  <v-col cols="8" md="8" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly outlined :background-color="getColor(conferencia.venda.lucro, false)" hide-details dense
                      v-model="conferencia.venda.lucro" label="Lucro(R$)">
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" md="4" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly outlined :background-color="getColor(conferencia.venda.lucro, false)" hide-details dense
                      v-model="conferencia.venda.porcentagem_lucro" label="%">
                    </v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <v-row dense class="pa-2">
                <v-col cols="12" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined dense
                    v-model="conferencia.venda.valor_recebido" label="Valor Frete Recebido"></v-text-field>
                </v-col>
                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.venda.diferenca_venda_auditoria, false)" dense
                    persistent-placeholder v-model="conferencia.venda.diferenca_venda_auditoria" label="Dif. Venda X Auditoria">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.venda.porcentagemDifVendaAuditoria, false)" dense
                    v-model="conferencia.venda.porcentagemDifVendaAuditoria" label="%"></v-text-field>
                </v-col>
              </v-row>
            </fieldset>
          </v-card>
        </v-col>

        <v-col xs="12" sm="12" md="6" lg="3" xl="3">
          <v-card class="pa-2 d-flex flex-column justify-space-between" style="height: 100%; width: 100%;">
            <!-- Início Field Embarque -->
            <fieldset style="background-color: white;">
              <legend class="legendHeaderStyle">Embarque</legend>
              <fieldset style="margin-bottom: 5px; margin-left: 10px; margin-right: 10px;">
                <legend class="legendStyle">Base de Cálculo</legend>
                <v-row dense class="pa-2">
                  <v-col cols="12" class="py-0 pb-2">
                    <v-text-field color="#018454" readonly class="py-0" hide-details outlined dense v-model="conferencia.embarque.valor_nf_embarque" label="Valor NF"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field color="#018454" readonly class="py-0" hide-details outlined dense v-model="conferencia.embarque.embarque_peso" label="Peso"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </fieldset>
              <div>
              <v-row dense class="pa-2 pb-0 ">
                <v-col cols="12" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details class="py-0" outlined dense v-model="conferencia.embarque.embarque_custo_frete" label="Valor Frete Embarcado"
                    ></v-text-field>
                </v-col>

                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.diferenca_peso_venda_embarque, true)" dense
                    v-model="conferencia.embarque.diferenca_peso_venda_embarque" label="Dif. Peso Venda x Embarque">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.porcentagemDifPesoVendaEmbarque, true)" dense
                    v-model="conferencia.embarque.porcentagemDifPesoVendaEmbarque" label="%">
                  </v-text-field>
                </v-col>
                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.diferenca_valor_venda_embarque, false)" dense
                    v-model="conferencia.embarque.diferenca_valor_venda_embarque" label="Dif. Valor Venda x Embarque">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.porcentagemDifVendaVendaEmbarque, false)" dense
                    v-model="conferencia.embarque.porcentagemDifVendaVendaEmbarque" label="%">
                  </v-text-field>
                </v-col>
                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.lucro_embarque, false)" dense
                    v-model="conferencia.embarque.lucro_embarque" label="Lucro Após Embarque">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.embarque.porcentagemLucroEmbarque, false)" dense
                    v-model="conferencia.embarque.porcentagemLucroEmbarque" label="%">
                  </v-text-field>
                </v-col>
              </v-row>
              </div>
            </fieldset>

            <fieldset class="mt-2">
              <legend class="legendHeaderStyle">Lucro Final da Operação</legend>
              <v-row dense class="pa-2">
                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly outlined :background-color="getColor(conferencia.venda.lucro, false)" hide-details dense
                    v-model="conferencia.venda.lucro" label="Lucro Venda(R$)">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly outlined :background-color="getColor(conferencia.venda.lucro, false)" hide-details dense
                    v-model="conferencia.venda.porcentagem_lucro" label="%">
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="py-0 pb-2">
                  <v-text-field :loading="loading" color="#018454" readonly hide-details outlined dense v-model="conferencia.cte.valor_pago_transportadora" label="Valor Pago Transportadora">
                  </v-text-field>
                </v-col>
                <v-col cols="8" md="8" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.lucro.lucro_final, false)" dense
                    v-model="conferencia.lucro.lucro_final" label="Lucro Final(R$)"></v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0 pb-2">
                  <v-text-field color="#018454" readonly hide-details outlined :background-color="getColor(conferencia.lucro.lucro_final, false)" dense
                    v-model="conferencia.lucro.porcentagem_lucro_final" label="%"></v-text-field>
                </v-col>
              </v-row>
            </fieldset>
          </v-card>
        </v-col>

        <v-col :cols="6" class="pl-0" style="height: fit-content;">
          <v-data-table :loading="grid.cte.carregando" hide-default-footer :headers="grid.cte.cabecalho"
          :items="grid.cte.items" class="overflow-x-auto elevation-1">

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="pl-0 pt-0">Tabela CTE</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="#018454"
                  style="color: white"
                  elevation="2"
                  small
                  @click="recalcularFrete()"
                >Recalcular Frete</v-btn>
              </v-toolbar>
            </template>

            <template #item.tipo_cte="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.tipo_cte }}</span>
                </template>
                <span v-if="item.tipo_cte == 'A'">Ajudantes</span>
                <span v-else-if="item.tipo_cte == 'B'">Transbordo da Carga</span>
                <span v-else-if="item.tipo_cte == 'C'">Complementar</span>
                <span v-else-if="item.tipo_cte == 'D'">Conhecimento de Devolução</span>
                <span v-else-if="item.tipo_cte == 'E'">Normal de Entrada</span>
                <span v-else-if="item.tipo_cte == 'F'">Frete de Coleta</span>
                <span v-else-if="item.tipo_cte == 'H'">Cobrança de Stretch Film</span>
                <span v-else-if="item.tipo_cte == 'I'">Estadias</span>
                <span v-else-if="item.tipo_cte == 'M'">Despesas com Manuseio da Carga</span>
                <span v-else-if="item.tipo_cte == 'N'">Normal</span>
                <span v-else-if="item.tipo_cte == 'O'">Normal de Retorno</span>
                <span v-else-if="item.tipo_cte == 'P'">Transporte de Pallets</span>
                <span v-else-if="item.tipo_cte == 'R'">Conhecimento de Reentrega</span>
                <span v-else-if="item.tipo_cte == 'S'">Normal de Saída</span>
                <span v-else-if="item.tipo_cte == 'T'">Normal de Transferência Interna</span>
                <span v-else-if="item.tipo_cte == 'U'">Custo de Comunicação</span>
                <span v-else-if="item.tipo_cte == 'W'">Complementar de Retorno</span>
                <span v-else-if="item.tipo_cte == 'X'">Complementar de Entrada</span>
                <span v-else-if="item.tipo_cte == 'Y'">Complementar de Saída</span>
                <span v-else-if="item.tipo_cte == 'Z'">Complementar de Transf. Interna</span>
              </v-tooltip>
            </template>

            <template #item.valor_cte>
              R${{ conferencia.cte.valor_pago_transportadora }}
            </template>

            <template #item.peso_cobrado>
              {{ conferencia.cte.peso_cobrado }}
            </template>

            <template #item.valor_tabela>
              R${{ conferencia.cte.valor_auditado_cte_tabela }}
            </template>

            <template #item.diferenca>
              R${{ conferencia.cte.diferenca }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    <!-- Action Buttons -->
    <v-card outlined class="mt-4 mb-2">
      <v-card-text class="pt-2">
        <v-row dense>
          <v-col cols="12" md="3" sm="3">
            <v-btn block color="#018454" style="color: white;" @click="modal.prod = true;">
              Produtos
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-btn block color="#018454" style="color: white;" @click="modal.cotacao = true;">
              Cotação
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-btn block color="#018454" style="color: white;" @click="modal.embalamento = true;">
              Embalamento
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-btn block color="#018454" style="color: white;" @click="modal.status = true;">
              Inf. Adicionais
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim Conferencia -->
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";

export default {
  name: "Rastreamento",
  props: {
    consulta: { type: Object },
  },
  data: () => ({
    showPeso: false,
    codigo_empresa: '',
    loading: false,
    // Telas
    modal: {
      prod: false,
      cotacao: false,
      embalamento: false,
      status: false,
      RequisicaoCotacao: false,
      RequisicaoEmbalamento: false,
    },
    detalhes: {
      order_id: null,
      numero_pedido: null,
      transportadora_nome: null,
      servico: null,
      subcanal: null,
      base_transportadora: null,
    },
    conferencia: {
      venda: {
        valor_nf: null,
        peso: null,
        valor_frete: null,
        valor_cobrado: null,
        acrescimo: null,
        porcentagem_base: null,
        difal: null,
        porcentagem_difal: null,
        taxa: null,
        valor_recebido: null,
        desconto_frete: null,
        porcentagem_desconto: null,
        lucro: null,
        porcentagem_lucro: null,
        diferenca_venda_auditoria: null,
        porcentagemDifVendaAuditoria: null,
      },
      embarque: {
        valor_nf_embarque: null,
        embarque_peso: null,
        embarque_custo_frete: null,
        diferenca_peso_venda_embarque: null,
        porcentagemDifPesoVendaEmbarque: null,
        diferenca_valor_venda_embarque: null,
        porcentagemDifVendaVendaEmbarque: null,
        lucro_embarque: null,
        porcentagemLucroEmbarque: null,
      },
      cte: {
        valor_nf_embarque: null,
        peso_cobrado: null,
        peso_real: null,
        peso_m3: null,
        peso_cubado: null,
        valor_pago_transportadora: null,
        diferenca_peso_embarque_cte: null,
        porcentagem_peso_embarque_cte: null,
        diferenca_valor_embarque_cte: null,
        porcentagem_valor_embarque_cte: null,
        numero_cte: null,
        valor_auditado_cte_tabela: null,
        diferenca_valor_cte_tabela: null,
        porcentagem_valor_cte_tabela: null,
      },
      redespacho: 0.0,
      lucro: {
        lucro_final: null,
        porcentagem_lucro_final: null,
      },
    },
    grid: {
      cte: {
        cabecalho: [
        { text: "Nº CTE", value: "numero_cte", sortable: false },
        { text: "Tipo", value: "tipo_cte", sortable: false },
        { text: "Valor CTE(R$)", value: "valor_cte", sortable: false },
        { text: "Peso Cobrado(Kg)", value: "peso_cobrado", sortable: false },
        { text: "Valor Tabela(R$)", value: "valor_tabela", sortable: false },
        { text: "Diferença(R$)", value: "diferenca", sortable: false },
        ],
        itens: [],
        carregando: false,
      },
      produtos: {
        cabecalho: [
          { text: "Código", value: "codigo" },
          { text: "Quantidade", value: "quantidade", align: "right" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        items: [],
      },
      cotacao: {
        cabecalho: [
          { text: "Código", value: "sku" },
          { text: "Quantidade", value: "quantity", align: "right" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoCotacao: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo Transp", value: "prazo transporte", align: "right" },
          { text: "Aliq. acresc. token", value: "aliquota_acrescimo_token", align: "right" },
          { text: "Base acresc. token", value: "base_acrescimo_token", align: "right" },
          { text: "Valor acresc. token", value: "valor_acrescimo_token", align: "right" },
          { text: "Base difal", value: "base_difal", align: "right" },
          { text: "Valor difal", value: "valor_difal", align: "right" },
          { text: "Valor produto difal", value: "valor_produto_difal", align: "right" },
          { text: "Aliquota difal", value: "aliquota_difal", align: "right" },
          { text: "Tipo difal", value: "tipo_difal" },
          { text: "Base calc. adicional", value: "base_calc_adicional", align: "right" },
        ],
        items: [],
      },
      produtosEmbalamento: {
        cabecalho: [
          { text: "Quantidade", value: "quantidade" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        cabecalhoUS: [
          { text: "Quantidade", value: "quantity" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
        ],
        items: [],
      },
      retornoEmbalamento: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Valor frete", value: "valor_frete", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo transporte", value: "prazo_transporte", align: "right" }
        ],
        items: [],
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
  }),

  async mounted(){
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    await this.carregarDetalhes(this.consulta.item);
    this.carregarCtePedido(this.consulta.item.cod_empresa, this.conferencia.cte.numero_cte);
  },
  
  methods: {
    carregarView(item) {
      this.modal.detalhes = true;
      this.detData = item
    },

    async pedidoConfere() {
      let url = `${baseApiUrl}/pedido-confere/${this.consulta.item.cod_empresa}/${this.consulta.item.numero_pedido}`

      await axios.get(url)
        .then((res) => {
          showSuccess(res.data.mensagem)
        }).catch((err) => {
          console.log(err)
          showError("Não foi possível concluir a solicitação!")
        })
    },

    async carregarCtePedido(cod_empresa, numero_cte) {
      this.grid.cte.carregando = true;
      try {
        let url = `${baseApiUrl}/cte-order-list?codempresa=${cod_empresa}&numerocte=${numero_cte}`
        const res = await axios.get(url)
        if(!res.data.retorno) {
          throw new Error("Retorno falso da API")
        }
      } catch (error) {
        let url = `${baseApiUrl}/cte-order/${cod_empresa}/${this.consulta.item.numero_pedido}`
        try {
          const res = await axios.get(url)
          this.grid.cte.items = res.data.dados
        } catch(err) {
          console.log(err)
          showError("Não foi possível carregar os dados da tabela CTE!")
        }
      } finally {
        this.grid.cte.carregando = false;
      }
    },

    recalcularFrete () {
      this.loading = true
      let url = `${baseApiUrl}/pedido-confere/${this.codigo_empresa}/${this.detalhes.numero_pedido}`;
      axios.get(url)
      .then(() => {
        showSuccess("Frete recalculado com sucesso");
        this.loading = false
      })
    },

    async carregarDetalhes(item){
      let url = `${baseApiUrl}/objetos-conferencia?objeto=${item.codigo}`;
      await axios
        .get(url)
        .then((res) => {
          this.detalhes.order_id = res.data.dados[0].order_id;
          this.detalhes.base_transportadora = res.data.dados[0].base_transportadora;
          this.detalhes.numero_pedido = res.data.dados[0].numero_pedido;
          this.detalhes.transportadora_nome = res.data.dados[0].transportadora_nome;
          this.detalhes.subcanal = res.data.dados[0].sub_canal;
          this.detalhes.servico = res.data.dados[0].servico_entrega;

          /** Venda */
          this.conferencia.venda.valor_nf = res.data.dados[0].venda.valor_nf;
          this.conferencia.venda.peso = res.data.dados[0].venda.peso;
          this.conferencia.venda.valor_frete = res.data.dados[0].venda.valor_frete;
          this.conferencia.venda.valor_cobrado = res.data.dados[0].venda.valor_cobrado;
          this.conferencia.venda.acrescimo = res.data.dados[0].venda.acrescimo;
          this.conferencia.venda.porcentagem_base = res.data.dados[0].venda.porcentagem_base;
          this.conferencia.venda.difal = res.data.dados[0].venda.difal;
          this.conferencia.venda.porcentagem_difal = res.data.dados[0].venda.porcentagem_difal;
          this.conferencia.venda.taxa = res.data.dados[0].venda.taxa;
          this.conferencia.venda.valor_recebido = res.data.dados[0].venda.valor_recebido;
          this.conferencia.venda.desconto_frete = res.data.dados[0].venda.desconto_frete;
          this.conferencia.venda.porcentagem_desconto = res.data.dados[0].venda.procentagem_desconto;
          this.conferencia.venda.lucro = res.data.dados[0].venda.lucro;
          this.conferencia.venda.porcentagem_lucro = res.data.dados[0].venda.porcentagem_lucro_venda;
          this.conferencia.venda.diferenca_venda_auditoria = res.data.dados[0].venda.diferenca_venda_auditoria;
          this.conferencia.venda.porcentagemDifVendaAuditoria = res.data.dados[0].venda.porcentagemDifVendaAuditoria;

          /** Embarque */
          this.conferencia.embarque.valor_nf_embarque = res.data.dados[0].embarque.valor_nf_embarque;
          this.conferencia.embarque.embarque_peso = res.data.dados[0].embarque.embarque_peso;
          this.conferencia.embarque.embarque_custo_frete = res.data.dados[0].embarque.embarque_custo_frete;
          this.conferencia.embarque.diferenca_peso_venda_embarque = res.data.dados[0].embarque.diferenca_peso_venda_embarque;
          this.conferencia.embarque.porcentagemDifPesoVendaEmbarque = res.data.dados[0].embarque.porcentagemDifPesoVendaEmbarque;
          this.conferencia.embarque.diferenca_valor_venda_embarque = res.data.dados[0].embarque.diferenca_valor_venda_embarque;
          this.conferencia.embarque.porcentagemDifVendaVendaEmbarque = res.data.dados[0].embarque.porcentagemDifVendaVendaEmbarque;
          this.conferencia.embarque.lucro_embarque = res.data.dados[0].embarque.lucro_embarque;
          this.conferencia.embarque.porcentagemLucroEmbarque = res.data.dados[0].embarque.porcentagemLucroEmbarque;

          /** CTE */
          this.conferencia.cte.valor_nf_embarque = res.data.dados[0].cte.valor_nf_embarque;
          this.conferencia.cte.peso_cobrado = res.data.dados[0].cte.peso;
          this.conferencia.cte.peso_real = res.data.dados[0].cte.peso_real;
          this.conferencia.cte.peso_m3 = res.data.dados[0].cte.peso_m3;
          this.conferencia.cte.peso_cubado = res.data.dados[0].cte.peso_cubado;
          this.conferencia.cte.valor_pago_transportadora = res.data.dados[0].cte.valor_pago_transportadora;
          this.conferencia.cte.diferenca_peso_embarque_cte = res.data.dados[0].cte.diferenca_peso_embarque_cte;
          this.conferencia.cte.porcentagem_peso_embarque_cte = res.data.dados[0].cte.porcentagem_peso_embarque_cte;
          this.conferencia.cte.diferenca_valor_embarque_cte = res.data.dados[0].cte.diferenca_valor_embarque_cte;
          this.conferencia.cte.porcentagem_valor_embarque_cte = res.data.dados[0].cte.porcentagem_valor_embarque_cte;
          this.conferencia.cte.numero_cte = res.data.dados[0].cte.numero_cte;
          this.conferencia.cte.valor_auditado_cte_tabela = res.data.dados[0].cte.valor_auditado_cte_tabela;
          this.conferencia.cte.diferenca_valor_cte_tabela = res.data.dados[0].cte.diferenca_valor_cte_tabela;
          this.conferencia.cte.porcentagem_valor_cte_tabela = res.data.dados[0].cte.porcentagem_valor_cte_tabela;

          this.conferencia.cte.diferenca = parseFloat(this.conferencia.cte.valor_pago_transportadora.replace(",", "."))
          - parseFloat(this.conferencia.cte.valor_auditado_cte_tabela.replace(",", "."));

          /** Redespacho */

          /** Lucro Final */
          this.conferencia.lucro.lucro_final = res.data.dados[0].lucro_final;
          this.conferencia.lucro.porcentagem_lucro_final = res.data.dados[0].porcentagem_lucro_final;
        })
    },

    getColor(item, peso){
      if(typeof(item) == "string"){
        if(peso == false){
          if(item != "0,00" && item != "0,00%" && item.includes("-") == false)
            return "#C8E6C9";
          if(item.includes("-")){
            return "rgba(255, 0, 0, 0.30)";
          }
          if(item == "0,00" || item == "0,00%"){
            return "";
          }
        }else{
          if(item != "0,00" && item != "0,00%" && item.includes("-") == false){
            return "rgba(255, 0, 0, 0.30)";
          }
          if(item.includes("-"))
            return "#C8E6C9";
          if(item == "0,00" || item == "0,00%")
            return "";
        }
      }
    }
  },

  computed: {
    computedHeaders(){
      return this.grid.cte.cabecalho.filter((element) => !this.arr.includes(element.value))
    },
  },
}
</script>

<style scoped>
.legendStyle {
  margin-left: 10px;
  padding-inline: 5px;
  font-weight: bolder;
}


.widthField {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 6px;
}

.legendHeaderStyle {
  margin-left: 10px;
  padding-inline: 5px;
  font-weight: bolder;
  color: black;
}

.colStyle {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 6px;
}

.rowField {
  padding-left: 10px;
  height: 60px;
}

.color-green>>>fieldset {
  background-color: #C8E6C9 !important;
}

.color-green>>>.v-text-field__slot label {
  color: black;
}

.color-red>>>fieldset {
  background-color: #FFCDD2 !important;
}

.color-red>>>.v-text-field__slot label {
  color: black;
}

.cardTextStyle {
  color: white;
  font-weight: bold;
}

.btnDet {
  border: 1px solid black;
  background-color: rgb(1, 134, 86) !important;
  color: white;
  font-size: 13px;
  margin-bottom: 10px;
}

.color-green--is-focused .color-green__slot {
  border-color: black !important;
}

.v-input >>> .v-label{
  font-weight: bold;
  color: black;
}

</style>