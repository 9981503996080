<template>
  <article>
    <!-- Inicio url consulta -->
    <v-dialog v-model="url.urlconsulta" class="text-center" persistent width="700">
      <v-card>
        <v-card-text style="padding-top: 17px">
          {{ this.url.token }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#018656" text @click="urlCop()">
            COPIAR E FECHAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warnning" text @click="url.urlconsulta = false">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim url consulta -->

    <!-- Inicio alterar rastreio objeto -->
    <v-dialog v-model="objRastreioAlter" class="text-center" persistent width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Alterar Rastreio Objeto
        </v-card-title>
        <v-card-text style="padding-top: 17px">

          <v-text-field dense outlined label="Rastreio Atual" type="text" autocomplete="off"
            v-model="consulta.item.numero_rastreio" :readonly="true"
          >
          </v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="padding-top: 17px">
          <v-text-field dense outlined label="Rastreio Novo" type="text" autocomplete="off"
            v-model="objAlterRastreio"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="objRastreioAlter = false">
            CANCELAR
          </v-btn>
          <v-btn color="#018656" text @click="saveAlterRastreio()">
            ENVIAR
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim alterar rastreio objeto -->

    <!-- Inicio img consulta -->
    <v-dialog v-model="img.imgconsulta" class="text-center" persistent width="700">
      <v-card>
        <v-card-text style="padding-top: 17px">
          <v-row>
            Imagem do objeto
          </v-row>
          <v-row>
            <v-img :src="this.img.svgImg"></v-img>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" dark text @click="img.imgconsulta = false">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim img consulta -->

    <!-- Início detalhes -->
    <v-dialog v-model="consulta.detalhes" fullscreen transition="dialog-bottom-transition">
      <div style="height: 100%;">
        <v-card style="height: 100%;">
          <v-toolbar dense color="#018656" dark class="mb-0">
            <v-icon class="ml-2"> fas fa-boxes </v-icon>
            <v-toolbar-title class="pl-4">
              Detalhes do objeto ({{ consulta.item.numero_pedido }})
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-if="this.btnImage">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text @click="imgConsulta()" v-on="on">
                    <v-icon size="30">fas fa-image</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Imagem</span>
              </v-tooltip>
            </template>

            <v-btn text @click="url.urlconsulta = true, urlConsulta()">
              Url Consulta
            </v-btn>
            <v-app-bar-nav-icon @click="consulta.detalhes = false, $emit('detclose', true)">
              <v-icon>fas fa-times</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar>

          <v-tabs v-model="tabInd" dense color="#018656" slider-color="#018656" class="mb-0">
            <v-row align="center" no-gutters dense class="ma-1">
              <v-tab style="height: 100%;" href="#Rastreio">Rastreio</v-tab>
              <v-tab style="height: 100%;" href="#Conferencia">Conferência</v-tab>
            </v-row>

            <v-tab-item value="Rastreio">
              <v-card-text class="mb-0">
                <Rastreamento :consulta="consulta" :encerrar="encerrar" :route="route" />
              </v-card-text>
            </v-tab-item>

            <v-tab-item value="Conferencia">
              <v-card-text class="pt-2 pb-0">
                <Conferencia :consulta="consulta"/>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </div>
    </v-dialog>
    <!-- Fim Detalhes -->
  </article>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../Template.vue";
import Rastreamento from "./frmrastreamento.vue";
import Conferencia from "./frmconferencia.vue";

export default {
  name: "Detalhes",
  components: {
    Template,
    Rastreamento,
    Conferencia,
  },
  props: {
    consulta: { type: Object },
    encerrar: { type: Boolean },
    route: { type: String },
  },
  data: () => ({
    codigo_empresa: '',
    tbRec: 0,
    tabInd: 0,
    grid: {
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
    ocor: {
      cabecalho: [
        { text: "Tentativa Entrega", value: "tentativa_entrega", sortable: false },
        { text: "Obj. Não Encontrado", value: "objeto_nao_encontrado", sortable: false },
        { text: "Extravio", value: "extravio", sortable: false },
        { text: "Roubo", value: "roubo", sortable: false },
        { text: "Falha Remetente", value: "falha_remetente", sortable: false },
        { text: "Falha Fiscal", value: "falha_fiscal", sortable: false },
        { text: "Atraso", value: "atraso", sortable: false },
        { text: "Aguardando Retirada", value: "aguardando_retirada", sortable: false },
        { text: "Avaria", value: "avaria", sortable: false },
        { text: "Alerta", value: "alerta", sortable: false },
        { text: "Tratativa Pendente", value: "tratativa_pendente", sortable: false },
        { text: "", value: "controls", sortable: false },
      ],
      items: [],
      carregando: false,
      paginaAtual: 1,
    },

    detalhes: {
      enviado: { data_postagem: "", data_considerada: "", cep: "", cidade: "", },
      para: { nome: "", cep: "", cidade: "", bairro: "", rua: "", },
      cod_servico: '',
    },
    itensTipo: [{ id: "R", descricao: "Reclamação" },
    { id: "I", descricao: "Ressarcimento" },
    ],
    url: { urlconsulta: false, token: '', },
    img: { imgconsulta: false, svgImg: '', },
    btnImage: false,
    saveData: false,
    objRastreioAlter: false,
    objAlterRastreio: '',
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    this.tbRec = this.consulta.aba_rastreio == true ? 1 : 0;
  },
  async beforeMount() {
    this.tabItems(this.consulta.aba_rastreio ? 1 : 0);
  },
  methods: {
    tabItems(tab) {
      this.tbRec = tab;
    },
    async urlCop() {
      await navigator.clipboard.writeText(this.url.token);
      this.url.urlconsulta = false;
    },
    async urlConsulta() {
      let url = `${baseApiUrl}/objeto-url`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${this.consulta.item.numero_rastreio}`;
      url += `&simpleBagq=Simple`;
      url += `&xspaceBagq=${this.consulta.item.codigo}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.url.token = `https://rastreieja.com.br/${response.data.dados.id}`;
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    async imgConsulta() {
      console.log("Acessado img");
      let url = `${baseApiUrl}/objeto-img`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${this.consulta.item.numero_rastreio}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.img.svgImg = `${response.data.dados}`;
            this.img.imgconsulta = true;
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: `${response.data.message}`,
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });

          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },
    async saveAlterRastreio() {

      this.$swal({
        title: 'Ops!!!',
        text: 'Em breve será liberado!!!',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      })
      .finally(() => {
        this.objRastreioAlter = false;
      });

    },
    btnSave() {
      this.saveData = true;
    },
  },
  watch: {
    'detalhes.enviado.cep': {
      handler: function (val, oldVal) {
        if (oldVal.length > 0 && val != oldVal) {
          this.btnSave();
        }
      },
    },
    'detalhes.para.cep': {
      handler: function (val, oldVal) {
        if (oldVal.length > 0 && val != oldVal) {
          this.btnSave();
        }
      },
    },
    'detalhes.cod_servico': {
      handler: function (val, oldVal) {
        if (oldVal.length > 0 && val != oldVal) {
          this.btnSave();
        }
      },
    },
  },
};
</script>
<style scoped>
.active {
  background-color: #018656;
  color: #fff;
}

.cardDetalhes {
  background-color: #ebebeb;
  height: 200px;
}

.cardDetalhes:hover {
  background-color: #e0e0e0e0;
}

.cardDetalhesDanfe {
  background-color: #ebebeb;
}

.cardDetalhesDanfe:hover {
  background-color: #e0e0e0e0;
}

.btnSalvar {
  color: #fff !important;
  position: relative;
  right: 10px;
}

.fieldStyle {
  min-height: 181px;
}
</style>