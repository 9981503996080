<template>
  <v-navigation-drawer class="mx-auto" :value="value" @input="$emit('input', $event)" fixed temporary>
    <v-list class="pt-0" dense>
      <div v-for="menu in menus" :key="menu.titulo">
        <v-list-item v-if="menu.url" :to="menu.url" :exact="menu.exact" @click.stop="$emit('input', false)">
          <v-list-item-icon>
            <v-icon>{{ menu.icone }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.titulo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-else-if="menu.titulo" :value="menu.titulo == 'Dashboard' ? 'true' : ''">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ menu.icone }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.titulo }}</v-list-item-title>
          </template>

          <div v-for="item in menu.itens" :key="item.titulo">
            <v-list-item style="background-color: #e9ecef;" v-if="item.url" :to="item.url" :exact="item.exact"
              @click.stop="$emit('input', false)">
              <v-list-item-icon style="position: relative; left: 20px;">
                <v-icon>{{ item.icone }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="padding-left: 16px;">{{ item.titulo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group style="background-color: #e9ecef;" sub-group no-action
              v-else-if="item.titulo" :value="false">
              <template v-slot:activator>
                <v-list-item-icon style="position: relative; right: 26px;">
                  <v-icon>{{ item.icone }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
              </template>

              <div v-for="subitens in item.itens">
                <v-list-item style="background-color: #d2d8de;"
                  v-if="subitens.url" :to="subitens.url" :exact="subitens.exact" @click.stop="$emit('input', false)">
                  <v-list-item-title style="padding-left: 25px;">
                    {{ subitens.titulo }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-group style="background-color: #d2d8de;" sub-group no-action
                v-else-if="subitens.titulo" :value="false">
                  <template v-slot:activator>
                    <v-list-item-title>{{ subitens.titulo }}</v-list-item-title>
                  </template>

                  <div>
                    <v-list-item style="background-color: #bbc4ce;" v-for="subsubItens in subitens.itens"
                      v-if="subsubItens.url" :to="subsubItens.url" :exact="subsubItens.exact" @click.stop="$emit('input', false)">
                      <v-list-item-title style="padding-left: 40px;">
                        {{ subsubItens.titulo }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list-group>
              </div>
            </v-list-group>
          </div>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'Menu',
  props: {
    value: Boolean,
  },
  data() {
    return {
      icone: "fas fa-angle-down",
      menus: [
          {
            icone: 'fas fa-chart-line',
            titulo: 'Dashboard',
            itens: [
              {
                icone: 'fas fa-chart-pie',
                titulo: 'Indicadores',
                url: '/'
              },
              {
                icone: 'fas fa-shipping-fast',
                titulo: 'Por Transportadora',
                url: '/indicadores-transp'
              },
              {
                icone: 'fas fa-boxes',
                titulo: 'Integração',
                url: '/indicadores-integracao'
              },
            ]
          },
        {
          icone: 'fas fa-archive',
          titulo: 'Cadastros',
          itens: [
            {
              icone: 'fas fa-building',
              titulo: 'Empresas',
              url: '/empresas',
            },
            // {
            //   icone: 'fas fa-building',
            //   titulo: 'Empresas Antigo',
            //   url: '/empresas-antigo',
            // },
            {
              icone: 'fas fa-asterisk',
              titulo: 'Status de rastreios',
              url: '/status-rastreios',
            },
            {
              icone: 'fas fa-asterisk',
              titulo: 'Status rastreios Proceda',
              url: '/status-rastreios-proceda',
            },
            {
              icone: 'fas fa-shipping-fast',
              titulo: 'Transportadoras',
              url: '/transportadoras',
            },
            {
              icone: 'fas fa-user',
              titulo: 'Usuários',
              url: '/usuarios',
            },
            {
              icone: 'fas fa-hard-hat',
              titulo: 'Status Cron',
              url: '/status-crons',
            },
            {
              icone: 'fas fa-comments',
              titulo: 'Status Notificações',
              url: '/status-notifications',
            },
          ],
        },
        {
          icone: 'fas fa-chart-pie',
          titulo: 'Rastreamento',
          itens: [
            {
              icone: 'fas fa-headset',
              titulo: 'Chamados',
              url: '/chamados',
            },
            {
              icone: 'fas fa-bullhorn',
              titulo: 'Reclamações',
              itens: [
                {
                  icone: 'fas fa-file-export',
                  titulo: 'Exportação',
                  url: '/analise-reclamacao',
                },
              ],
            },
            {
              icone: 'fas fa-file-import',
              titulo: 'Importações',
              itens: [
                {
                  icone: 'fas fa-file-invoice',
                  titulo: 'Extrato ou CSV',
                  url: '/importacao-objetos',
                },
                {
                  icone: 'fas fa-file-invoice',
                  titulo: 'Retorno Reclamação',
                  url: '/importacao-retorno-correios-new',
                },
                {
                  icone: 'fas fa-file-invoice',
                  titulo: 'Proceda',
                  url: '/importacao-proceda',
                },
                {
                  icone: 'fas fa-file-invoice',
                  titulo: 'Pedidos',
                  url: '/importacao-pedidos',
                },
              ],
            },
            {
              icone: 'fas fa-box',
              titulo: 'Objetos',
              url: '/objetos',
            },
            {
              icone: 'fas fa-box',
              titulo: 'Pedidos',
              url: '/objetos-novo',
            },
            {
              icone: 'fas fa-file-alt',
              titulo: 'Relatórios',
              itens: [
                {
                  icone: 'fas fa-exclamation-triangle',
                  titulo: 'Objetos em Alerta',
                  url: '/relatorios/alerta',
                },
                {
                  icone: 'fas fa-tag',
                  titulo: 'Em Andamento',
                  url: '/relatorios/andamento',
                },
                {
                  icone: 'fas fa-truck-loading',
                  titulo: 'Aguardando Envio',
                  url: '/relatorios/aguardando-envio',
                },
                {
                  icone: 'fas fa-clock',
                  titulo: 'Atrasados',
                  url: '/atrasados',
                },
                {
                  icone: 'fas fa-dolly-flatbed',
                  titulo: 'Entregues',
                  url: '/entregues',
                },
                {
                  icone: 'fas fa-search',
                  titulo: 'Não encontrados',
                  url: '/nao-encontrados',
                },
                {
                  icone: 'fas fa-dolly-flatbed',
                  titulo: 'Pós Entrega',
                  url: '/movimento-pos-entrega',
                },
                {
                  icone: 'fas fa-bullhorn',
                  titulo: 'Reclamações',
                  url: '/reclamacoes-objetos',
                },
                {
                  icone: 'fas fa-shoe-prints',
                  titulo: 'Sem movimentação',
                  url: '/sem-movimentacao',
                },{
                  icone: 'fas fa-shoe-prints',
                  titulo: 'Relatório Mensal',
                  url: '/relatorio-mensal',
                },
              ],
            },
            {
              icone: 'fas fa-cogs',
              titulo: 'Integrações',
              itens: [
                {
                  icone: 'fas fa-code',
                  titulo: 'Tratativas pendentes',
                  url: '/tratativas-pendentes',
                },
                {
                  icone: 'fas fa-file-invoice',
                  titulo: 'Notas não localizadas',
                  url: '/notas-nao-localizadas',
                },
                {
                  icone: 'fas fa-user',
                  titulo: 'Log integração',
                  url: '/log-integracao',
                },
                {
                  icone: 'fas fa-user',
                  titulo: 'Novo Log integração',
                  url: '/novo-log-integracao',
                },
                // {
                //   icone: 'fas fa-store',
                //   titulo: 'Canais de Venda',
                //   url: '/canal-vendas',
                // },
                {
                  icone: 'fas fa-truck',
                  titulo: 'Depara Transportadora',
                  url: '/depara-transportadoras',
                },
              ],
            },
            {
              icone: 'fas fa-cog',
              titulo: 'Configurações',
              itens: [
                {
                  icone: 'fas fa-key',
                  titulo: 'Chaves Integrações',
                  url: '/chaves-integracoes',
                },
                {
                  icone: 'fas fa-key',
                  titulo: 'Chaves Cotação',
                  url: '/chaves-cotacao',
                },
                {
                  icone: 'fas fa-key',
                  titulo: 'Novo Chaves Cotação',
                  url: '/novo-chaves-cotacao',
                },
                {
                  icone: 'fas fa-shipping-fast',
                  titulo: 'Transportadoras',
                  url: '/config-transportadoras',
                },
              ],
            },
          ]
        },
        {
          icone: 'fas fa-boxes',
          titulo: 'Cotação',
          itens: [
            {
              icone: 'fas fa-user',
              titulo: 'Clientes',
              url: '/cadastro-clientes',
            },
            {
              icone: 'fas fa-truck',
              titulo: 'Nova cotação',
              url: '/nova-cotacao-frete'
            },
            {
              icone: 'fas fa-box',
              titulo: 'Produtos',
              itens: [
                {
                  icone: 'fas fa-box',
                  titulo: 'Listagem de Produtos',
                  url: '/cadastro-produtos'
                },
                {
                  icone: 'fas fa-file-import',
                  titulo: 'Importar Arquivos',
                  itens: [
                    {
                      icone: 'fas fa-file',
                      titulo: 'Importar Produtos',
                      url: '/importar-produtos',
                    },
                    {
                      icone: 'fas fa-file',
                      titulo: 'Importar Agregados',
                      url: '/importar-agregados',
                    },
                    {
                      icone: 'fas fa-file',
                      titulo: 'Importar Volumes Extras',
                      url: '/importar-volumes-extras',
                    }
                  ]
                }
              ]              
            },
            {
              icone: 'fas fa-industry',
              titulo: 'Centros de Distribuição',
              url: '/centros-de-distribuicao',
            },
            {
              icone: 'fas fa-dolly',
              titulo: 'Modais',
              url: '/modais',
            },
          ]
        },
        {
          icone: 'fas fa-file',
          titulo: 'Arquivos',
          itens: [
            {
              icone: 'fas fa-file-upload',
              titulo: 'Upload NFE',
              url: '/upload-nfe'
            }
          ],
        }
      ],
    }
  },
  watch: {
    'value': function (val, oldVal) {
      if (val) {
        this.$el.querySelector(".v-navigation-drawer__content").scrollTop = 0
      }
    },
  },
}
</script>

<style>
.bg-active {
  background-color: #018656 !important;
}

.item-list-active {
  color: #f8f8ff !important;
}

.bordered {
  left: 60px;
  position: absolute;
  bottom: 0px;
  height: 30%;
  border-bottom: 1px solid #01734a !important;
  width: 73%;
  z-index: 1000;
}

.v-navigation-drawer__content {
  margin-bottom: 10px !important;
}

.v-list-group__header>.v-list-item {
  padding: 0 !important;
}

.v-list-group__header__append-icon>i {
  font-size: 12px !important;
  display: flex;
  margin-left: auto;
}

.v-list-group__append-icon>i {
  float: right;
  display: flex;
  margin-left: auto;
  font-size: 12px !important;
}


.v-list-group__header__prepend-icon>i {
  left: 205px;
  font-size: 18px !important;
}

.v-list-group__prepend-icon>i {
  left: 205px;
  font-size: 18px !important;
}

.v-list-group__items {
  background: rgba(255, 255, 255, 0.9) !important;
}

.v-list-item--active {
  /* verde */
  background: #018656 !important;
  /*background: #1e88e5 !important; */
  color: #72777a !important;
  /* border-bottom: 1px solid #01734a !important;*/
  border-bottom: 1px solid #d2d8de !important;
}

.v-list-item--active .v-list-item__icon {
  color: #f8f8ff !important;
}

.v-list-item__icon--active {
  color: #f8f8ff !important;
}

.v-list-item--active .v-list-item__title {
  color: #f8f8ff !important;
}

.v-navigation-drawer {
  background: white !important;
}

.v-list-item {
  /* border-bottom: 1px black; */
  color: black !important;
}
</style>