<template>
  <div>
    <!-- Início filtro -->
    <Filtro :exibir="modal.filtro" :cancelarFiltro="() => cancelarFiltro()" :salvarFiltro="() => salvarFiltro()"
    :propFiltros="filtros" :propFiltrosDatas="filtrosDatas" :titulo="title"/>
    <!-- Fim filtro -->

    <!-- Início detalhes -->
    <Detalhes v-if="detmodal" v-on:detclose="fecharDetalhes" :consulta="objeto" :route="route"
    :encerrar="encerrar" />
    <!-- Fim detalhes -->

    <v-card outlined>
      <v-card-text style="padding: 5px; padding-left: 20px; padding-right: 20px; margin: 0px;">
        <v-row dense align="start" justify="start" style="margin-top: 10px;">
          <!-- Data Início -->
          <v-col cols="12" sm="4" md="3" style="height: 60px;">
            <v-text-field color="#018656" dense outlined label="Data inicial" prepend-icon="fas fa-calendar-alt"
              :value="formatarData(camposDatas.dataInicial)" @click="() => camposDatas.exibirData = true">
            </v-text-field>
          </v-col>

          <!-- Data Fim -->
          <v-col cols="12" sm="4" md="3" style="height: 60px;">
            <v-text-field color="#018656" dense outlined label="Data final"
              :value="formatarData(camposDatas.dataFinal)" @click="() => camposDatas.exibirData = true">
            </v-text-field>
          </v-col>

          <!-- Calendário Datas -->
          <v-dialog v-model="camposDatas.exibirData" width="fit-content">
            <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
              <v-card-text class="mt-6">
                <v-card>
                  <v-card-text style="padding-top: 10px;">
                    <v-row dense>
                      <v-col>
                        <h1
                          style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                          Data inicial
                        </h1>
                        <v-date-picker color="#018656" v-model="camposDatas.dataInicial" size="100" width="100%">
                        </v-date-picker>
                      </v-col>

                      <v-col>
                        <h1
                          style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                          Data final
                        </h1>
                        <v-date-picker color="#018656" v-model="camposDatas.dataFinal" size="100" width="100%">
                        </v-date-picker>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row style="margin-top: 10px;" dense justify="end">
                  <v-col cols="12" sm="12">
                    <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                      @click="camposDatas.exibirData = false, limparFiltros(), carregarObjetos()">
                      CONFIRMAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <!-- Filtro -->
          <v-col cols="12" sm="3" md="2" style="height: 60px;">
            <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 40px;"
              @click="() => { limparFiltros(), carregarObjetos() }">
              <v-icon dense class="mr-2">fas fa-broom</v-icon>
              LIMPAR
            </v-btn>
          </v-col>

          <v-col cols="12" sm="3" md="2" style="height: 60px;">
            <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 40px;"
              @click="() => modal.filtro = true">
              <v-icon dense class="mx-2">fas fa-filter</v-icon>
              FILTRAR
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Inicio Tabela -->
    <v-card outlined style="margin-top: 10px;">
      <v-row dense align="center" class="ma-1 ml-0 mr-0">
        <v-col cols="12">
          <v-card-title class="pl-0">
            <v-text-field style="width: 100%;" class="mx-4" color="#018656" prepend-icon="fas fa-search"
              label="Pesquisa rápida" single-line hide-details v-model="grid.objetos.pesquisa" clearable>
            </v-text-field>
          </v-card-title>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text>
        <!-- Tabs -->
        <v-row v-if="tabActive">
          <v-col cols="12" class="px-6 pb-0">
            <v-tabs color="#fff" v-model="tabOrd" slider-color="red" flat active-class="active">
              <v-tab v-if="$route.name == 'Atrasados'" v-for="item in tabAtr" :key="item.value" @click="getSearch(item.value)">
                {{ item.tab }}
              </v-tab>
              <v-tab v-if="$route.name == 'Entregues'" v-for="item in tabEnt" :key="item.value" @click="getSearch(item.value)">
                {{ item.tab }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Tabela -->
        <v-row :style="tabActive ? 'height: calc(100vh - 475px);' : 'height: calc(100vh - 415px);'" class="overflow-y-auto">
          <v-col class="px-6 py-6">
            <v-data-table :show-select="$route.name == 'Em Andamento' || $route.name == 'Aguardando Envio'" color="#018656" style="color: black;" dense :headers="computedHeaders"
              :items="grid.objetos.items" :search="grid.objetos.pesquisa" :items-per-page="grid.objetos.porPagina"
              hide-default-footer class="elevation-1 pt-4" :loading="grid.objetos.carregando" item-key="codigo" 
              v-model="grid.objetos.itemSelecionado" fixed-header :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
              @update:sort-by="sortTable" @update:sort-desc="sortTable">

              <template v-slot:header.data-table-select="{ header }">
                <v-checkbox color="#018454" v-model="selectAll"></v-checkbox>
              </template>

              <template v-slot:header.aguardando_envio="{ header }">
                <div class="d-flex">
                  <span>{{ header.text }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" x-small class="ml-2">fas fa-info-circle</v-icon>
                    </template>
                    <span>Aguardando Envio</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:header.tempo_expedicao="{ header }">
                <div class="d-flex">
                  <span>{{ header.text }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" x-small class="ml-2">fas fa-info-circle</v-icon>
                    </template>
                    <span>Tempo de Expedição</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:item.data-table-select="{ item }">
                <div :style="(($route.name == 'Em Andamento' && Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30)
                || ($route.name == 'Aguardando Envio')) ? 'display: block;' : 'display: none;'">
                  <v-checkbox :value="item" color="#018454" v-model="grid.objetos.itemSelecionado"></v-checkbox>
                </div>
              </template>

              <template #item.numero_pedido="{ item }">
                <a @click="carregarDetalhes(item)" style="text-decoration: none; color: #018656; font-weight: bold;">
                  {{ item.numero_pedido }}
                </a>
              </template>
              <template #item.reclamacao="{ item }">
                {{ item.reclamacao == 1 ? "Sim" : "" }}
              </template>
              <template #item.alerta="{ item }">
                <v-icon v-if="item.alerta == 1" size="16">fas fa-exclamation-triangle</v-icon>
              </template>

              <template #item.acoes="{ item }">
                <div class="d-flex align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="encerrarShopee(item)" v-on="on" dense x-small fab class="elevation-0 mt-1 mb-1" v-if="showShopee(item)" color="#ef233c" style="color: white;">
                        CS
                      </v-btn>
                    </template>
                    <span>Cancelar Shopee</span>
                  </v-tooltip>
                  
                  <v-tooltip v-if="$route.name == 'Em Andamento' && Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small @click="objectEncerrar(item)" color="error">
                        Finalizar
                      </v-btn>
                    </template>
                  </v-tooltip>

                  <v-tooltip v-else-if="$route.name == 'Aguardando Envio' && !showShopee(item)" top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="objectDelete(item)">
                        <v-icon small>fas fa-trash</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </div>
              </template>

              <!-- This template looks for headers with formatters and executes them -->
              <template 
              v-for="header in grid.objetos.cabecalho.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                {{ header.formatter(value) }}
              </template>

            </v-data-table>
          </v-col>
        </v-row>

        <!-- Contador de Páginas -->
        <v-row style="margin-top: 15px; position: relative;">
          <v-col v-if="grid.objetos.itemSelecionado.length > 0 && $route.name == 'Aguardando Envio'" cols="2" class="d-flex justify-start align-end">            
            <v-btn width="150" @click="objectDelete(grid.objetos.itemSelecionado)" color="error">
              Excluir
            </v-btn>
          </v-col>

          <v-col v-else-if="grid.objetos.itemSelecionado.length > 0 && $route.name == 'Em Andamento'" cols="2" class="d-flex justify-start align-end">
            <v-btn width="150" @click="objectEncerrar(grid.objetos.itemSelecionado)" color="error">
              Finalizar
            </v-btn>
          </v-col>

          <v-col cols="3" style="max-height: 65px; position: absolute; left: 0; right: 0; margin: auto;">
            <v-pagination v-model="grid.objetos.paginaAtual" style="color: #018656" color="#018656"
              :length="Math.ceil(grid.objetos.totalRegistros / this.grid.objetos.porPagina)" @input="mudarPagina"
              :total-visible="5"></v-pagination>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="d-none d-md-flex" cols="2" style="max-height: 65px;">
            <v-select color="#018656" dense outlined label="Resultados por página" :items="grid.objetos.qtdsPaginacao" item-text="text"
              item-value="value" v-model="grid.objetos.porPagina"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { baseApiUrl, showSuccess } from "@/global";
import axios from "axios";
import moment from "moment";
import Filtro, { filtros, filtrosDatas } from "./Filtro.vue";
import Detalhes from "../../components/objetos/frmdetalhes.vue";

/** Cancela os filtros atuais. */
function cancelarFiltro() {
  this.modal.filtro = false;
}

/** Salva os filtros atuais. */
function salvarFiltro() {
  this.modal.filtro = false;
  this.carregarObjetos();
}

export default {
  name: "Data",
  components: {
    Filtro,
    Detalhes,
  },
  props: {
    title: { type: String },
    dataInicio: { type: String },
    dataFinal: { type: String },
    indicadores: { type: Boolean },
  },

  data: () => ({
    active: false,
    multipleDelete: false,
    encerrar: false,
    route: '',
    selectAll: false,
    filtrosDatas,
    sortBy: [],
    sortDesc: [],
    camposDatas: {
      exibirData: false,
      dataInicial: null,
      dataFinal: null,
    },
    modal: {
      filtro: false,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
    },
    grid: {
      objetos: {
        cabecalho: [
          { text: "Num. Pedido", value: "numero_pedido", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Nota Fiscal", value: "nota_fiscal", sortable: false },
          { text: "Prazo", value: "prazo_entrega" },
          { text: "Cód. Serviço", value: "cod_servico", sortable: false },
          { text: "Data Venda", value: "data_venda", formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "A.E", value: "aguardando_envio", },
          { text: "Data Considerada", value: "data_considerada", formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "T.E", value: "tempo_expedicao", },
          { text: "Data Prevista", value: "data_prevista", formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Entrega", value: "data_entrega", formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Movimentação", value: "data_ultima_posicao", formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Movimentação", value: "ultima_posicao", sortable: false },
          { text: "Rec", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
          { text: "Ações", value: "acoes", sortable: false },
        ],
        items: [],
        carregando: false,
        pesquisa: "",
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 50,
        itemSelecionado: [],
        coluna: "objetos.data_venda",
        direcao: "asc",
        ordenacao: {
          colunas: [],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      }
    },
    filtros: {
      codigo: "",
      transportadoras: "",
      rastreio: "",
      cep_origem: "",
      cep_destino: "",
    },
    objeto: {
      aba_rastreio: true,
    },

    tabOrd: 0,
    /* Objetos Atrasados */
    tabAtr: [
      { tab: "Sem Entrega", content: "SemEntrega", value: 1 },
      { tab: "Com Entrega", content: "ComEntrega", value: 2 },
    ],

    /* Objetos Entregues */
    tabEnt: [
      { tab: "Normal", content: "normal", value: 1 },
      { tab: "Com Atraso", content: "atraso", value: 2 },
    ],
    tabActive: false,
    detmodal: false,
    atrasos: "SS",
    entregues: "SS",
  }),

  created(){
    if(this.$route.name == 'Atrasados' || this.$route.name == 'Entregues' || this.$route.name == 'Reclamações')
      this.tabActive = true;
    this.route = this.$route.name;
    this.camposDatas.dataInicial = this.dataInicio;
    this.camposDatas.dataFinal = this.dataFinal;
    this.carregarObjetos();
  },

  methods: {
    cancelarFiltro,
    salvarFiltro,

    sortTable(){
      if(this.sortBy[0] != null){
        if(this.sortBy[0] == "aguardando_envio" || this.sortBy[0] == "tempo_expedicao")
          this.grid.objetos.coluna = this.sortBy[0];
        else
          this.grid.objetos.coluna = `objetos.${this.sortBy[0]}`;
        if(this.sortDesc == false || this.sortDesc[0] == false)
          this.grid.objetos.direcao = 'asc';
        else
          this.grid.objetos.direcao = 'desc';
      }else{
        this.grid.objetos.coluna = 'objetos.data_venda';
        this.grid.objetos.direcao = 'asc';
      }

      this.carregarObjetos();
    },

    limparFiltros(){
      this.filtros.codigo = "";
      this.filtros.transportadoras = "";
      this.filtros.canal_venda = "";
      this.filtros.rastreio = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";

      this.filtrosDatas.postagem.inicial = '';
      this.filtrosDatas.postagem.final = '';
      this.filtrosDatas.postagem.exibir = false;
      this.filtrosDatas.considerada.inicial = '';
      this.filtrosDatas.considerada.final = '';
      this.filtrosDatas.considerada.sem_data = false;
      this.filtrosDatas.considerada.exibir = false;
      this.filtrosDatas.prevista.inicial = '';
      this.filtrosDatas.prevista.final = '';
      this.filtrosDatas.prevista.sem_data = false;
      this.filtrosDatas.prevista.exibir = false;
      this.filtrosDatas.entrega.inicial = '';
      this.filtrosDatas.entrega.final = '';
      this.filtrosDatas.entrega.sem_data = false;
      this.filtrosDatas.entrega.exibir = false;
      this.filtrosDatas.ultimaPosicao.inicial = '';
      this.filtrosDatas.ultimaPosicao.final = '';
      this.filtrosDatas.ultimaPosicao.exibir = false;

      this.grid.objetos.porPagina = 50;
      this.grid.objetos.paginaAtual = 1;
      this.grid.objetos.pesquisa = "";
      
      // this.camposDatas.dataInicial = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30) ).format("YYYY-MM-DD");
      // this.camposDatas.dataFinal = moment(new Date()).format("YYYY-MM-DD");

      if(this.tabOrd > 0){
        this.getSearch(1);
        this.tabOrd = 0;
      }
      // else
      //   this.carregarObjetos();
    },
    
    formatarData(data) {
      return moment(data).format('DD/MM/YYYY');
    },

    getSearch(value) {
      if (value == 1) {
        if(this.$route.name == 'Atrasados')
          this.atrasos = "SS";
        else
          this.entregues = "SS";
      }
      else if (value == 2) {
        if(this.$route.name == 'Atrasados')
          this.atrasos = "SC";
        else
          this.entregues = "SC";
      }
    },

    async carregarObjetos() {
      this.grid.objetos.carregando = true;
      this.grid.objetos.items = [];
      this.grid.objetos.itemSelecionado = [];
      this.selectAll = false;
      let url = `${baseApiUrl}/objetos`;
      url += `?coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`;
      if(this.filtros.codigo == "" && this.filtros.rastreio == ""){
        url += `&fim=${moment(this.camposDatas.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.camposDatas.dataInicial).format("YYYY-MM-DD")}`;
      }

      if(this.indicadores == true)
        url += `&indicadores=true`;

      if(this.$route.name == 'Em Andamento'){
        url += `&entregue=N`;
        url += `&em_andamento=true`;
      }
      if(this.$route.name == 'Aguardando Envio'){
        url += `&entregue=N`;
        url += `&aguardando_envio=true`;
      }
      if(this.$route.name == 'Atrasados')
        url += `&atrasado=${this.atrasos}`;
      if(this.$route.name == 'Entregues')
        url += `&entregue=${this.entregues}`;
      if(this.$route.name == 'Não encontrados')
        url += `&nao_encontrado=S`
      if(this.$route.name == 'Movimento Pos Entrega')
        url += `?pos_entrega=S`;
      if(this.$route.name == 'Sem Movimentacao')
        url += `&sem_movimentacao=S`;

      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;
      if (this.filtros.codigo != '' && this.filtros.codigo != null){
        url += `&codigo=${this.filtros.codigo}`;
        this.grid.objetos.paginaAtual = 1;
      }
      if (this.filtros.transportadoras != '' && this.filtros.transportadoras != null){
        url += `&cod_transportadora=${this.filtros.transportadoras}`;
        // this.grid.objetos.paginaAtual = 1;
      }
      if(this.filtros.canal_venda != '' && this.filtros.canal_venda != null){
        url += `&canal_venda=${this.filtros.canal_venda}`;
        this.grid.objetos.paginaAtual = 1;
      }
      if(this.filtros.rastreio != '' && this.filtros.rastreio != null){
        url += `&numero_rastreio=${this.filtros.rastreio}`;      
        this.grid.objetos.paginaAtual = 1;
      }
      if (
        this.filtrosDatas.considerada.inicial != '' && this.filtrosDatas.considerada.inicial != null &&
        this.filtrosDatas.considerada.final != '' && this.filtrosDatas.considerada.final != null
      ) {
        url += `&data_considerada_inicio=${this.filtrosDatas.considerada.inicial}&data_considerada_fim=${this.filtrosDatas.considerada.final}`;
        this.grid.objetos.paginaAtual = 1;
      }else if(this.filtrosDatas.considerada.sem_data == true){
        url += `&sem_data_considerada=true`;
      }
      if (
        this.filtrosDatas.prevista.inicial != '' && this.filtrosDatas.prevista.inicial != null &&
        this.filtrosDatas.prevista.final != '' && this.filtrosDatas.prevista.final != null
      ) {
        url += `&data_prevista_inicio=${this.filtrosDatas.prevista.inicial}&data_prevista_fim=${this.filtrosDatas.prevista.final}`;
        this.grid.objetos.paginaAtual = 1;
      }else if(this.filtrosDatas.prevista.sem_data == true){
        url += `&sem_data_prevista=true`;
      }
      if (
        this.filtrosDatas.entrega.inicial != '' && this.filtrosDatas.entrega.inicial != null &&
        this.filtrosDatas.entrega.final != '' && this.filtrosDatas.entrega.final != null
      ){
        url += `&data_entrega_inicio=${this.filtrosDatas.entrega.inicial}&data_entrega_fim=${this.filtrosDatas.entrega.final}`;
        this.grid.objetos.paginaAtual = 1;
      }else if(this.filtrosDatas.entrega.sem_data == true){
        url += `&sem_data_entrega=true`;
      }
      if (this.filtros.cepOrigem != '' && this.filtros.cepOrigem != null){
        url += `&remetente_cep=${this.filtros.cepOrigem}`;
        this.grid.objetos.paginaAtual = 1;
      }

      if(this.filtros.cepDestino != '' && this.filtros.cepDestino != null){
        url += `&destinatario_cep=${this.filtros.cepDestino}`;
        this.grid.objetos.paginaAtual = 1;
      }
      if(
        this.filtrosDatas.ultimaPosicao.inicial != '' && this.filtrosDatas.ultimaPosicao.inicial != null &&
        this.filtrosDatas.ultimaPosicao.final != '' && this.filtrosDatas.ultimaPosicao.final != null
      ){
        let date_inicio = moment(this.filtrosDatas.ultimaPosicao.inicial).toDate();
        let date_fim = moment(this.filtrosDatas.ultimaPosicao.final).toDate();

        date_fim.setHours(0);
        date_fim.setMinutes(0);
        date_fim.setSeconds(0);
        date_fim.setMilliseconds(0);

        date_inicio.setHours(0);
        date_inicio.setMinutes(0);
        date_inicio.setSeconds(0);
        date_inicio.setMilliseconds(0);
        
        this.filtrosDatas.ultimaPosicao.inicial = moment(date_inicio).format("YYYY-MM-DD HH:mm:ss");
        this.filtrosDatas.ultimaPosicao.final = moment(date_fim).format("YYYY-MM-DD HH:mm:ss");
        
        url += `&data_ultima_posicao_inicio=${this.filtrosDatas.ultimaPosicao.inicial}
        &data_ultima_posicao_fim=${this.filtrosDatas.ultimaPosicao.final}`;
        this.grid.objetos.paginaAtual = 1;
      }
      url += `&page=${this.grid.objetos.paginaAtual}`;
      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;

      await axios
        .get(url)
        .then((res) => {
          this.grid.objetos.items = res.data.dados.data;
          this.grid.objetos.totalRegistros = res.data.dados.total;
          this.grid.objetos.porPagina = Number(res.data.dados.per_page);
        })
        .finally(() => (this.grid.objetos.carregando = false));
    },
    
    async carregarDetalhes(item) {
      if(this.$route.name == 'Em Andamento' && Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30){
        this.encerrar = true;
      }else{
        this.encerrar = false;
      }
      this.objeto.item = item;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    fecharDetalhes() {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
        aba_rastreio: true,
      };
    },

    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },

    async objectEncerrar(objeto) {
      let data = moment(new Date()).format("YYYY-MM-DD");
      let objetos = "";
      let num_pedido = "";

      if(Object.values(objeto).length == 24){
        objetos = objeto.codigo;
        num_pedido = objeto.numero_pedido;
      }else{
        for(let cont = 0; cont < Object.values(objeto).length; cont++){
          objetos += objeto[cont].codigo;
          num_pedido += objeto[cont].numero_pedido;
          if (cont+1 < Object.values(objeto).length) {
            objetos += ',';
            num_pedido += ', ';
          }
        }
      }

      let url = `${baseApiUrl}/objetos-encerrar?objeto=${objetos}`;

      this.$swal({
        title: 'Confirmação',
        html: `Tem certeza que deseja encerrar o(s) objeto(s):<br>${num_pedido}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        customClass: {
          htmlContainer: 'text-wrap',
        },
      }).then(async(res) => {
        if(res.isConfirmed) {
          await axios
            .patch(url, {
              "status": "F",
              "data_encerramento": data,
            }).then(() => {
              if(objeto.length != null){
                for(let cont = 0; cont < objeto.length; cont++){
                  for(let i = 0; i < this.grid.objetos.items.length; i++){
                    if(this.grid.objetos.items[i].codigo == objeto[cont].codigo){
                      this.grid.objetos.items.splice(i, 1);
                    }
                  }
                }
              }else{
                for(let i = 0; i < this.grid.objetos.items.length; i++){
                  if(this.grid.objetos.items[i].codigo == objeto.codigo){
                    this.grid.objetos.items.splice(i, 1);              
                  }
                }
              }
              this.grid.objetos.itemSelecionado = [];
              showSuccess("Objetos finalizados com sucesso!");
            }).catch(() => {
              this.$swal({
                title: 'Que pena!!!',
                text: `Não foi possível finalizar os objetos.`,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            }
          );
        }
      })
    },

    async objectDelete(objeto){
      let objetos = "";
      let num_pedido = "";
      
      if(Object.values(objeto).length == 24){
        objetos = objeto.codigo;
        num_pedido = objeto.numero_pedido;
      }else{
        for(let cont = 0; cont < Object.values(objeto).length; cont++){
          objetos += objeto[cont].codigo;
          num_pedido += objeto[cont].numero_pedido;
          if (cont+1 < Object.values(objeto).length) {
            objetos += ',';
            num_pedido += ', ';
          }
        }
      }
      
      let url = `${baseApiUrl}/objetos-excluir?objeto=${objetos}`;

      this.$swal({
        title: 'Confirmação',
        html: `Tem certeza que deseja excluir o(s) objeto(s):<br>${num_pedido}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        customClass: {
          htmlContainer: 'text-wrap',
        },
      }).then(async(res) => {
        if(res.isConfirmed){
          await axios
          .delete(url)
            .then(() => {
              if(objeto.length != null){
                for(let cont = 0; cont < objeto.length; cont++){
                  for(let i = 0; i < this.grid.objetos.items.length; i++){
                    if(this.grid.objetos.items[i].codigo == objeto[cont].codigo){
                      this.grid.objetos.items.splice(i, 1);
                    }
                  }
                }
              }else{
                for(let i = 0; i < this.grid.objetos.items.length; i++){
                  if(this.grid.objetos.items[i].codigo == objeto.codigo){
                    this.grid.objetos.items.splice(i, 1);              
                  }
                }
              }
              this.grid.objetos.itemSelecionado = [];
              showSuccess("Objetos excluídos com sucesso!");
            })
            .catch(() => {
              this.$swal({
                title: 'Que pena!!!',
                text: `Não foi possível excluir o(s) objeto(s) ${num_pedido}.`,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            })
        }
      })
    },
    
    showShopee(item) {
      if(this.$route.name == 'Aguardando Envio' && item.canal_venda == 'Shopee' && item.ultima_posicao == null && item.data_considerada == null
      && item.data_prevista == null && item.data_entrega == null) {
        return true
      }
    },

    async encerrarShopee(objeto) {
      this.$swal({
        title: 'Confirmação',
        text: `Deseja realmente notificar o cancelamento à Shopee do objeto ${objeto.numero_pedido}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          let url = `${baseApiUrl}/notifyChannel/${this.codigo_empresa}/${objeto.numero_rastreio}`;
          let data = moment(new Date()).format("YYYY-MM-DD");
          let data_hora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          
          await axios.get(url, {
            params: {
              info: [
                {
                  name: "Shopee",
                  info_line: {
                      status: "C",
                      cod_situacao: 0,
                      data_entrega: data,
                      data_ultima_posicao: data_hora,
                      ultima_posicao: "FINALIZACAO",
                      slug: "CANCELADO",
                      time_line: "5"
                    }
                }
              ]
            }
          }).then(() => {
            showSuccess("Notificação de cancelamento efetuada com sucesso!");
          }).catch(() => {
            showError("Erro na notificação do objeto");
          })
        }
      });
    },

    cancelExclusao(){
      this.multipleDelete = false;
      this.grid.objetos.itemSelecionado = [];
    }
  },

  computed: {
    computedHeaders(){
      if(this.$route.name == 'Em Andamento')
        return this.grid.objetos.cabecalho.filter(item=>item.text!='Nota Fiscal' && item.text!='A.E')
      if(this.$route.name == 'Aguardando Envio')
        return this.grid.objetos.cabecalho.filter(item=>item.text!='Data Considerada' && item.text!='T.E');
      if(this.$route.name == 'Atrasados' || this.$route.name == 'Entregues' || this.$route.name == 'Movimento Pos Entrega')
        return this.grid.objetos.cabecalho.filter(
          item => item.text!='Serviço' && item.text!='Nota Fiscal' && item.text!='Data Venda' && item.text!='Ações'
        )
      if(this.$route.name == 'Não encontrados' || this.$route.name == 'Sem Movimentacao')
        return this.grid.objetos.cabecalho.filter(
          item => item.text!='Nota Fiscal' && item.text!='Data Venda' && item.text!='Data Considerada' && item.text!='Ações'
        )
    },
  },

  watch: {
    "selectAll": {
      handler: function (val) {
        if(val){
          if(this.$route.name == 'Em Andamento'){
            for(let i = 0; i < this.grid.objetos.items.length; i++){
              if(Math.round((new Date().getTime() - new Date(this.grid.objetos.items[i].data_prevista).getTime()) / (1000 * 3600 * 24)) > 30){
                this.grid.objetos.itemSelecionado.push(this.grid.objetos.items[i]);
              }
            }
          }else{
            this.grid.objetos.itemSelecionado = this.grid.objetos.items;
          }
        }else{
          this.grid.objetos.itemSelecionado = [];
        }
      }
    },

    "tabOrd": {
      handler: function (val, oldVal) {
        if(val != oldVal){
          this.mudarPagina(1);
        }
      }
    },

    "multipleDelete": {
      handler: function (val) {
        if(val == false)
          this.grid.objetos.itemSelecionado = [];
      }
    },

    "camposDatas.dataInicial": {
      handler: function (val, oldVal) {
        if(val != oldVal){
          this.grid.objetos.paginaAtual = 1;
        }
      }
    },

    "camposDatas.dataFinal": {
      handler: function (val, oldVal) {
        if(val != oldVal){
          this.grid.objetos.paginaAtual = 1;
        }
      }
    },

    "objeto.numero_pedido": {
      handler: function (val) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.cod_empresa = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },
}
</script>

<style>
.active {
  background-color: #018656;
  color: #fff;
}

.text-wrap{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 87%;
  justify-self: center;
  margin-bottom: 0px;
}

.textStyle{
  color: #2196f3;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>