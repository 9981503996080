<script>

import axios from 'axios';
import { baseApiUrlPersys } from '@/global';
import moment from 'moment';

/** @type {Map<string, any>[]} */
let transportadoras = [];

/** @return  {Promise<Map<string, any>[]>} */
async function carregarTransportadoras(token) {
  const url = `${baseApiUrlPersys}/transportadora/empresa`;

  /** @type {Map<string, any>[]} */
  let transportadoras = [];

  await axios.get(url, {
    headers: {
      'Token-API': token
    }
  }).then((res) => {
    transportadoras = res.data;

    transportadoras.sort((a, b) => {
      const nameA = a.nome.toUpperCase();
      const nameB = b.nome.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  });

  return transportadoras;
}

/** Filtros. Deve ser inserido como prop nesse componente. */
export const filtros = {
  codigo: '',
  transportadoras: '',
  rastreio: '',
  cepOrigem: '',
  cepDestino: '',
};

/** Filtros de data. Deve ser inserido como prop nesse componente. */
export const filtrosDatas = {
  postagem: {
    inicial: '',
    final: '',
    exibir: false,
  },

  considerada: {
    inicial: '',
    final: '',
    exibir: false,
  },

  prevista: {
    inicial: '',
    final: '',
    exibir: false,
  },

  entrega: {
    inicial: '',
    final: '',
    exibir: false,
  },

  ultimaPosicao: {
    inicial: '',
    final: '',
    exibir: false,
  },

  /**
   * @param {string} data
   */
  formatarData(data) {
    return moment(data).format('DD/MM/YYYY');
  },

  /**
   * @param {Object} data
   * @param {string} data.inicial
   * @param {string} data.final
   * @param {boolean} data.exibir
   */
  cancelarData(data) {
    data.inicial = '';
    data.final = '';
    data.exibir = false;
  }
};

function limparFiltro() {
  this.propFiltros.codigo = '';
  this.propFiltros.transportadoras = '';
  this.propFiltros.canal_venda = '';
  this.propFiltros.rastreio = '';
  this.propFiltros.cepOrigem = '';
  this.propFiltros.cepDestino = '';

  this.propFiltrosDatas.postagem.inicial = '';
  this.propFiltrosDatas.postagem.final = '';
  this.propFiltrosDatas.postagem.exibir = false;
  this.propFiltrosDatas.considerada.inicial = '';
  this.propFiltrosDatas.considerada.final = '';
  this.propFiltrosDatas.considerada.exibir = false;
  this.propFiltrosDatas.prevista.inicial = '';
  this.propFiltrosDatas.prevista.final = '';
  this.propFiltrosDatas.prevista.exibir = false;
  this.propFiltrosDatas.entrega.inicial = '';
  this.propFiltrosDatas.entrega.final = '';
  this.propFiltrosDatas.entrega.exibir = false;
  this.propFiltrosDatas.ultimaPosicao.inicial = '';
  this.propFiltrosDatas.ultimaPosicao.final = '';
  this.propFiltrosDatas.ultimaPosicao.exibir = false;
}

export default {
  name: 'Filtro',

  props: ['exibir', 'salvarFiltro', 'propFiltros', 'propFiltrosDatas', 'titulo', 'cancelarFiltro'],

  data: () => ({
    cod_persys: null,
    transportadoras,
  }),

  methods: {
    limparFiltro,
  },

  async created() {
    this.cod_persys = this.$store.state.token_persys;
    this.transportadoras = await carregarTransportadoras(this.cod_persys);    
  },
}

</script>

<template>
  <v-dialog v-model="exibir" @click:outside="() => cancelarFiltro()">
    <v-card outlined v-on:keyup.enter="salvarFiltro()">
      <div class="d-flex align-center">
        <v-card-title class="pt-6 mb-2" style="font-weight: normal;">
          <v-icon class="mr-2">fas fa-filter</v-icon>
          Filtros - {{ titulo }}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-btn class="mr-2" icon @click="() => cancelarFiltro()">
          <v-icon color="red">fas fa-times</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6" md="3">
            <v-text-field clearable outlined type="text" append-icon="fas fa-hashtag" name="codigo" label="Número Pedido"
              autocomplete="off" v-model="propFiltros.codigo" color="#018656">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select clearable outlined append-icon="fas fa-truck" name="transportadora" label="Transportadora"
              autocomplete="off" v-model="propFiltros.transportadoras" :items="transportadoras" item-text="nome"
              item-value="codigoSisfrete" color="#018656">
            </v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field color="#018454" outlined append-icon="fas fa-shopping-cart" label="Canal de Vendas" type="text" autocomplete="off"
              v-model="propFiltros.canal_venda" clearable></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field clearable outlined append-icon="fas fa-map-marked-alt" name="rastreio" label="Rastreio"
              autocomplete="off" v-model="propFiltros.rastreio" color="#018656">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="postagem"
            label="Data de postagem" autocomplete="off" :value="propFiltrosDatas.postagem.inicial != '' &&
            propFiltrosDatas.postagem.final != '' ? 'De ' + propFiltrosDatas.formatarData(propFiltrosDatas.postagem.inicial) +
            ' até ' + propFiltrosDatas.formatarData(propFiltrosDatas.postagem.final) : ''"
              @click="() => propFiltrosDatas.postagem.exibir = true" color="#018656">
            </v-text-field>

            <v-dialog v-model="propFiltrosDatas.postagem.exibir" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.postagem.inicial" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.postagem.final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.postagem.exibir = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.cancelarData(propFiltrosDatas.postagem)">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="considerada"
              label="Data considerada" autocomplete="off" :value="propFiltrosDatas.considerada.inicial != '' &&
              propFiltrosDatas.considerada.final != '' ? 'De ' + propFiltrosDatas.formatarData(propFiltrosDatas.considerada.inicial) +
              ' até ' + propFiltrosDatas.formatarData(propFiltrosDatas.considerada.final) : ''"
              @click="() => propFiltrosDatas.considerada.exibir = true" color="#018656" hide-details>
            </v-text-field>

            <v-dialog v-model="propFiltrosDatas.considerada.exibir" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.considerada.inicial" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.considerada.final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.considerada.exibir = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.cancelarData(propFiltrosDatas.considerada)">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="prevista" label="Data prevista"
              autocomplete="off" :value="propFiltrosDatas.prevista.inicial != '' &&
              propFiltrosDatas.prevista.final != '' ? 'De ' + propFiltrosDatas.formatarData(propFiltrosDatas.prevista.inicial) +
              ' até ' + propFiltrosDatas.formatarData(propFiltrosDatas.prevista.final) : ''"
              @click="() => propFiltrosDatas.prevista.exibir = true" color="#018656" hide-details>
            </v-text-field>

            <v-dialog v-model="propFiltrosDatas.prevista.exibir" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.prevista.inicial" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.prevista.final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.prevista.exibir = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.cancelarData(propFiltrosDatas.prevista)">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="entrega" label="Data de entrega"
              autocomplete="off" :value="propFiltrosDatas.entrega.inicial != '' &&
              propFiltrosDatas.entrega.final != '' ? 'De ' + propFiltrosDatas.formatarData(propFiltrosDatas.entrega.inicial) +
              ' até ' + propFiltrosDatas.formatarData(propFiltrosDatas.entrega.final) : ''"
              @click="() => propFiltrosDatas.entrega.exibir = true" color="#018656" hide-details>
            </v-text-field>

            <v-dialog v-model="propFiltrosDatas.entrega.exibir" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.entrega.inicial" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.entrega.final" size="100" width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.entrega.exibir = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.cancelarData(propFiltrosDatas.entrega)">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field outlined type="text" append-icon="fas fa-calendar-alt" name="ultima_posicao"
              label="Data do último movimento" autocomplete="off" :value="propFiltrosDatas.ultimaPosicao.inicial != '' &&
                propFiltrosDatas.ultimaPosicao.final != '' ?
                'De ' + propFiltrosDatas.formatarData(propFiltrosDatas.ultimaPosicao.inicial) +
                ' até '
                + propFiltrosDatas.formatarData(propFiltrosDatas.ultimaPosicao.final) : ''"
              @click="() => propFiltrosDatas.ultimaPosicao.exibir = true" color="#018656">
            </v-text-field>

            <v-dialog v-model="propFiltrosDatas.ultimaPosicao.exibir" width="fit-content">
              <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                <v-card-text class="mt-6">
                  <v-card>
                    <v-card-text style="padding-top: 10px;">
                      <v-row dense>
                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data inicial
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.ultimaPosicao.inicial" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>

                        <v-col>
                          <h1
                            style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                            Data final
                          </h1>
                          <v-date-picker color="#018656" v-model="propFiltrosDatas.ultimaPosicao.final" size="100"
                            width="100%">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row style="margin-top: 10px;" dense justify="end">
                    <v-col cols="12" sm="6">
                      <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.ultimaPosicao.exibir = false">
                        CONFIRMAR
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                        @click="() => propFiltrosDatas.cancelarData(propFiltrosDatas.ultimaPosicao)">
                        CANCELAR E REMOVER
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field clearable outlined type="text" append-icon="fas fa-map-marker-alt" name="cep_origem"
              label="CEP de origem" autocomplete="off" v-model="propFiltros.cepOrigem" color="#018656">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field clearable outlined type="text" append-icon="fas fa-map-marker" name="cep_destino"
              label="CEP de destino" autocomplete="off" v-model="propFiltros.cepDestino" color="#018656">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="end" dense width="100%">
          <v-col cols="12" sm="3" md="2">
            <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 50px;"
              @click="() => limparFiltro()">
              <v-icon dense class="mr-2">fas fa-broom</v-icon>
              Limpar
            </v-btn>
          </v-col>

          <v-col cols="12" sm="3" md="2">
            <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 50px;"
              @click="() => salvarFiltro()">
              <v-icon dense class="mr-2">fas fa-filter</v-icon>
              FILTRAR
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
