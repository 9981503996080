<template>
  <article>
    <!-- Início principal -->
    <v-toolbar flat>
      <v-icon> fas fa-clock </v-icon>
      <v-toolbar-title class="pl-4"> Objetos Entregues </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <Data :title="$route.name" :dataInicio="camposDatas.dataInicial" :dataFinal="camposDatas.dataFinal" :indicadores="indicadores" />
    <!-- Fim principal -->
  </article>
</template>

<script>
import moment from "moment";
import Template from "../../components/Template.vue";
import Data from "./frmData.vue";

export default {
  name: "Entregues",
  components: {
    Template,
    Data
  },
  data: () => ({
    indicadores: false,
    camposDatas: {
      dataInicial: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      dataFinal: moment(new Date()).format('YYYY-MM-DD'),
    },
    
    codigo_empresa: '',
    tabOrd: 0,
    tabDat: [
      { tab: "Normal", content: "normal", value: 1 },
      { tab: "Com Atraso", content: "atraso", value: 2 },
    ],

    grid: {
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Prazo", value: "prazo_entrega", sortable: false },
          { text: "Código Serviço", value: "cod_servico", sortable: false },
          { text: "Dt. Cons.", value: "data_considerada", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. Prev", value: "data_prevista", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. Ent.", value: "data_entrega", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Dt. Mov.", value: "data_ultima_posicao", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Mov.", value: "ultima_posicao", sortable: false },
          { text: "Rec", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 50,
        itemSelecionado: [],
        coluna: "objetos.cod_empresa",
        direcao: "ASC",
      },
    },
    modal: {
      filtro: false,
      name: "Entregues",
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
      data_inicio: false,
      data_final: false,
      cod_servico: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      numero_rastreio: "",
      prazo: "",
      dt_considerada: "",
      dt_prevista: "",
      dt_entrega: "",
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao: "",
      ultima_posicao: "",
      data_inicio: null,
      data_final: null,
      cod_servico: "",
    },
    transportadoras: [],
    rastreios: [],
    detalhes: [],
    objetoAtual: "",
    loading: false,
    filtro: false,
    filtroFechar: false,
    objeto: {
      numero_rastreio: "",
      aba_rastreio: true,
    },
    detmodal: false,
    entregue: "SS",
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    if(this.$route.params.data_fim != null)
      this.routeLoaded();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.numero_rastreio = "";
      this.filtros.prazo = "";
      this.filtros.dt_considerada = "";
      this.filtros.dt_prevista = "";
      this.filtros.dt_entrega = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao = "";
      this.filtros.ultima_posicao = "";
      this.filtros.cod_servico = "";
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
      this.carregarObjetos();
    },
    fechar() {
      this.$router.push("/");
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },  
    routeLoaded() {
      let data_inicio = this.$route.params.data_inicio;
      let data_fim = this.$route.params.data_fim;
      this.indicadores = true;
      if(data_inicio == '' && data_fim == ''){
        data_inicio = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
        data_fim = moment(new Date()).format('YYYY-MM-DD');
      }
      this.camposDatas.dataInicial = data_inicio
      this.camposDatas.dataFinal = data_fim;
    },
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 50;
            this.mudarPagina(1);
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.active {
  background-color: #018656;
  color: #fff;
}
</style>
