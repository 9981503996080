<template>
  <div>

    <!-- Início send -->
    <v-dialog
        v-model="modal.sendMail"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
            Manifestação Email
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-select
                    :items="['Confirmação', 'Resposta', 'Pagamento', 'Prazo']"
                    label="Tipo"
                    required
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      label="Data"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                    locale="pt-br"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                  v-model="assunto"
                  label="Assunto"
                  hide-details="auto"
                  type="text"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
              >
                <v-textarea
                  outlined
                  name="descricao"
                  label="Descrição"
                  v-model="descricao"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="modal.sendMail = false" >
            Voltar
          </v-btn>
          <v-btn color="primary" text @click="modal.sendMail = false" >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim send -->

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="px-6">
            <v-data-table
              dense
              :headers="grid.manifesto.cabecalho"
              :items="grid.manifesto.items"
              hide-default-footer
              class="elevation-1"
              :loading="grid.manifesto.carregando"
              item-key="id"
            >
              <template #item.data="{ item }">
                {{ formatarDataBR(item.data) }}
              </template>

              <template v-slot:item.acoes="{ item }">
                <!-- botão marcar lido -->
                <div v-if="item.status">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-app-bar-nav-icon v-on="on" >
                        <v-icon size="12" class="acoes">fas fa-envelope-open</v-icon>
                      </v-app-bar-nav-icon>
                    </template>
                    <span>Já Lido</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <!-- <v-app-bar-nav-icon v-on="on" > -->
                        <!-- <v-icon size="16" color="green" class="acoes" @click="responderManifesto(item)">fas fa-pen</v-icon> -->
                      <!-- <v-app-bar-nav-icon v-on="on" >
                        <v-icon size="12" class="acoes" @click="actionSend('send', objeto)" >fas fa-envelope-open-text</v-icon>
                      </v-app-bar-nav-icon> -->
                      <v-app-bar-nav-icon v-on="on" >
                        <v-icon size="12" class="acoes" @click="MarkAsRead(item)" >fas fa-envelope</v-icon>
                      </v-app-bar-nav-icon>
                    </template>
                    <span>Marcar como Lido</span>
                  </v-tooltip>
                </div>
                <!-- botão pagamento -->
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on" >
                      <v-icon size="12" color="green" class="acoes" @click="MarkPagto(item)">fas fa-money-bill-wave</v-icon>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>Enviar para Pagamento</span>
                </v-tooltip>
                <!-- botão encerramento -->
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on" >
                      <v-icon size="12" color="red" class="acoes" @click="deleteItem(item)">fas fa-hourglass-end</v-icon>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>Encerrar Reclamação</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../Template.vue";

export default {
  name: "Interacoes",
  components: {
    Template,
  },
  props: {
    nroobjeto: { type: Object },
  },
  data: () => ({
    codigo_empresa: '',
    menu2: false,
    descricao: null,
    assunto: null,
    date: null,
    grid: {
      manifesto: {
        cabecalho: [
          { text: "Data", value: "data", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), width: '0%'},
          { text: "Assunto", value: "assunto", sortable: false, width: '28%'},
          { text: "Recebido", value: "texto", sortable: false, width: '63%' },
          { text: "Enviado", value: "resposta", sortable: false },
          { text: "Prazo", value: "prazo", sortable: false },
          { text: "Pagamento", value: "pagamento", sortable: false },
          // { text: "Status", value: "status", sortable: false },
          { text: "Ações", value: "acoes", sortable: false, align: 'center' },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      sendMail: false,
    },
    dataRetorno: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    console.log("Interacoes this.nroobjeto", this.nroobjeto);
    await this.carregarManifesto(this.nroobjeto);
  },
  methods: {
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
    MarkAsRead(item) {
      this.dataRetorno = false;
      this.$swal({
        title: 'MARCAR COMO LIDO?',
        text: `Alterar Status`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await this.enviarLido(item);
          if (this.dataRetorno) {
            await this.carregarManifesto(this.nroobjeto);
          }
        }
      });
    },
    async enviarLido(objUpd) {
      let url = `${baseApiUrl}/manifesto-leitura`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&codigo=${objUpd.codigo}`;
      url += `&numero_pi=${objUpd.reclamacoes_codigo}`;

      this.dataRetorno = false;
      await axios
        .get(url)
        .then((response) => {
          this.dataRetorno = response.data.retorno;
          if (this.dataRetorno) {
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Solicitação concluída.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: `${response.data.mensagem}`,
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          }

        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.grid.manifesto.carregando = false
        });
    },
    responderManifesto(item) {},
    actionSend(type, item) {
      if (type == "send") {
        this.modal.sendMail = true;
      } else if (type == "close") {
        this.modal.stopComplaint = true;
      }
    },
    async carregarManifesto(objeto) {
      this.grid.manifesto.carregando = true;
      // let url = `${baseApiUrl}/manifesto?numero_pi=${objeto.numero_pi}&numero_rastreio=${objeto.numero_rastreio}`;
      let url = `${baseApiUrl}/manifesto`;
        url += `?numero_pi=${objeto.numero_pi}`;
        url += `&numero_rastreio=${objeto.numero_rastreio}`;
        url += `&cod_empresa=${this.codigo_empresa}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.manifesto.items = res.data.dados;
        })
        .finally(() => {
          this.grid.manifesto.carregando = false
        });
    },
    carregarPi(item) {
      // this.objetoAtual = item.numero_rastreio;
      // this.objeto.numero_rastreio = item.numero_rastreio;
      // this.objeto.protocolo_retorno = item.protocolo_retorno;
      // this.protocolo_retorno = item.protocolo_retorno;
      // this.objeto.habilita = item.protocolo_retorno > 0;
      // this.grid.manifesto.items = [];
      // this.modal.pi = true;
      // this.modal.loading = true;
      // this.carregarRastreio(item);
      // this.carregarDetalhesObjeto(item);
      // this.carregarManifesto(item.protocolo_retorno);
      // this.modal.loading = false;
    },
  },
};
</script>

<style>
</style>