var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"padding-top":"0px"},attrs:{"cols":"12","md":"5","sm":"5","xl":"4"}},[_c('fieldset',{staticStyle:{"width":"100%","display":"flex","align-items":"flex-end"}},[_c('legend',{staticClass:"legendStyle"},[_c('b',[_vm._v("Detalhes")])]),_c('v-data-table',{staticClass:"elevation-0 pt-4",attrs:{"dense":"","headers":_vm.status.cabecalho,"items":_vm.statusIn,"hide-default-footer":"","loading":_vm.status.carregando},scopedSlots:_vm._u([{key:"header.analisar",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'I')?_c('span',[_vm._v("Importado")]):_vm._e(),(item.status == 'A')?_c('span',[_vm._v("Aberto")]):_vm._e(),(item.status == 'D')?_c('span',[_vm._v("Entregue")]):_vm._e(),(item.status == 'E')?_c('span',[_vm._v("Erro")]):_vm._e(),(item.status == 'F')?_c('span',[_vm._v("Finalizado")]):_vm._e(),(item.status == 'R')?_c('span',[_vm._v("Reprocessar")]):_vm._e(),(item.status == 'C')?_c('span',[_vm._v("Cancelado")]):_vm._e()]}},{key:"item.cod_situacao",fn:function(ref){
var item = ref.item;
return [(item.cod_situacao == 0)?_c('span',[_vm._v("Erro/Pausado")]):_vm._e(),(item.cod_situacao == 1)?_c('span',[_vm._v("Data Prevista")]):_vm._e(),(item.cod_situacao == 2)?_c('span',[_vm._v("Aberto/Rastreio")]):_vm._e(),(item.cod_situacao == 3)?_c('span',[_vm._v("Entregue/Finalizado")]):_vm._e()]}},{key:"item.time_line",fn:function(ref){
var item = ref.item;
return [(item.time_line == 0)?_c('span',[_vm._v("Aguardando Postagem")]):_vm._e(),(item.time_line == 1)?_c('span',[_vm._v("Postado")]):_vm._e(),(item.time_line == 2)?_c('span',[_vm._v("Transporte")]):_vm._e(),(item.time_line == 3)?_c('span',[_vm._v("Saiu Entrega")]):_vm._e(),(item.time_line == 4)?_c('span',[_vm._v("Entregue")]):_vm._e()]}},{key:"item.analisar",fn:function(ref){return [_c('div',[(_vm.isAnalisar || _vm.statusIn[0].analisar == 1)?_c('span',[_vm._v("Sim")]):_c('span',{on:{"click":function($event){return _vm.setAnalise()}}},[_vm._v("Não")])])]}}])}),_c('v-spacer'),(_vm.statusIn[0].devolvido == 1 || _vm.encerrar == true)?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"tile":"","small":"","dense":""},on:{"click":function($event){return _vm.objectEncerrar()}}},[_vm._v(" Finalizar ")]):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex",staticStyle:{"padding-top":"0px"},attrs:{"cols":"12","md":"7","sm":"7","xl":"8"}},[_c('fieldset',{staticClass:"fieldAlertas"},[_c('legend',{staticClass:"legendStyle"},[_vm._v("Alertas")]),_vm._l((_vm.ocorIn[0]),function(index,ocorrencia){return (index > 0)?_c('v-chip',{attrs:{"label":"","color":index > 0 ? 'red' : ''}},[_c('span',{class:index > 0 ? 'white--text': ''},[_vm._v(_vm._s(ocorrencia))])]):_vm._e()})],2)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }