<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="px-6">
            <v-data-table
              dense
              :headers="grid.detalhes.cabecalho"
              :items="this.grid.detalhes.items"
              :hide-default-footer="true"
              :items-per-page="50"
              class="elevation-1"
              :loading="grid.detalhes.carregando"
              item-key="codigo"
            >
              <template #item.notificado="{ item }">
                <template v-if="item.notificado == 1">
                  {{ "Sim" }}
                </template>
                <template v-else-if="item.notificado == 2">
                  {{  "Erro" }}
                </template>
                <template v-else>
                  {{ "" }}
                </template>
              </template>

              <!-- This template looks for headers with formatters and executes them -->
              <template
                v-for="header in grid.detalhes.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
  name: 'Notificacoes',
  props: {
    nroobjeto: { type: String },
  },
  data: () => ({
    codigo_empresa: '',
    grid: {
      detalhes: {
        cabecalho: [
          { text: "Id", value: "codigo", sortable: false },
          { text: "Status", value: "descricao", sortable: false },
          // { text: "Tipo", value: "tipostatus", sortable: false,  },
          { text: "Slug", value: "slug", sortable: false },
          { text: "Time Line", value: "time_line", sortable: false },
          { text: "Canal", value: "forma", sortable: false },
          { text: "Notificado", value: "notificado", sortable: false },
          { text: "Data da notificação", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null },
          { text: "Status da notificação", value: "error", sortable: false },
        ],
        items: [],
        carregando: false,
      },
    },
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    await this.carregarNotificacoesObjeto(this.nroobjeto);
  },
  watch: {
    nroobjeto(val) {
      this.carregarNotificacoesObjeto(val)
    }
  },
  methods: {
    async carregarNotificacoesObjeto(numero_rastreio) {
      this.grid.detalhes.items = [];
      this.grid.detalhes.carregando = true;
      let url = `${baseApiUrl}/company/${this.codigo_empresa}/tracking/${numero_rastreio}`;
      axios
        .get(url)
        .then((res) => {
          res.data.dados.data.forEach(element => {
// console.log("element",element, "element.idnotificacoes", element.idnotificacoes);
            let descricao = JSON.parse(element.info_rastreio).ultima_posicao;
            let slug = JSON.parse(element.info_rastreio).slug;
            let time_line = JSON.parse(element.info_rastreio).time_line;
            let error = JSON.parse(element.ret_notify);
            this.grid.detalhes.items.push({ codigo: element.idnotificacoes,
                                            descricao: descricao,
                                            slug: slug,
                                            time_line: time_line,
                                            forma: element.who_notifi,
                                            notificado: element.notificado,
                                            data_hora: element.data_notificado,
                                            error: error?.message ,
            })
            this.grid.detalhes.items.sort((a, b) => a.codigo - b.codigo);
          });
        })
        .finally(() => (this.grid.detalhes.carregando = false));
      this.grid.detalhes.carregando = false;
    },
  },
}
</script>

<style>
</style>