<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="px-6">
            <v-data-table
              dense
              color="#018656"
              :headers="grid.detalhes.cabecalho"
              :items="grid.detalhes.items"
              hide-default-footer
              :items-per-page="50"
              class="elevation-1"
              :loading="grid.detalhes.carregando"
            >
              <template #progress>
                <v-progress-linear indeterminate color="#018656" height="3" absolute style="width: 100%;"></v-progress-linear>
              </template>

              <template #item.endereco_retirada="{ item }">
                <div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" :style="width">
                  {{ formatarRetirada(item.endereco_retirada) }}
                </div>
              </template>

              <template #item.data_hora="{ item }">
                {{ formatarDataBR(item.data) }} {{ item.hora }}
              </template>

              <template #item.origem_cidade_uf_local="{ item }">
                {{ item.cidade }} {{ item.cidade != null && item.uf != null ? "/" : "" }} {{ item.uf }} {{ item.local != null && item.uf != null ? "-" : "" }} {{ item.local }} {{"/"}} {{ item.destino_cidade }} {{ item.destino_cidade != null && item.destino_uf != null ? "/" : "" }} {{ item.destino_uf }} {{ item.destino_local != null && item.destino_uf != null ? "-" : "" }} {{ item.destino_local }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
	name: "Rastreio",
  props: {
    nroobjeto: { type: String },
  },
  data: () => ({
    codigo_empresa: '',
    grid: {
      detalhes: {
        cabecalho: [
          { text: "Codigo", value: "codigo", sortable: false },
          { text: "Status", value: "descricao", sortable: false },
          { text: "Tipo", value: "tipostatus", sortable: false },
          { text: "Slug", value: "slug", sortable: false },
          { text: "Time Line", value: "time_line", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem/Destino", value: "origem_cidade_uf_local", sortable: false },
          { text: "Retirada", value: "endereco_retirada", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    await this.carregarRastreioObjetos(this.nroobjeto);
  },
  methods: {
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
    formatarRetirada(value) {
      if (value) {
        try {
          const stringJson = JSON.parse(value);
          return `${stringJson.logradouro}, ${stringJson.numero}, ${stringJson.bairro}, ${stringJson.cep}, ${stringJson.localidade}-${stringJson.uf}`;
        } catch {
          const stringJson = JSON.parse(JSON.stringify(value));
          return `${stringJson.logradouro}`;
        }
      }
    },
    salvarProtocoloReclamacao(nroPi) {
      if (nroPi != "") {
        alert("Gravar protocolo para o objeto " + this.objetoAtual);
      }
    },
    async carregarRastreioObjetos(numero_rastreio) {
      this.grid.detalhes.items = [];
      this.grid.detalhes.carregando = true;
      let url = `${baseApiUrl}/rastreios`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${numero_rastreio}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.detalhes.items = res.data.dados;
        })
        .finally(() => (this.grid.detalhes.carregando = false));
    },
  },

  watch: {
    nroobjeto(val) {
      this.carregarRastreioObjetos(val)
    }
  },

  computed: {
    width() {
      switch(this.$vuetify.breakpoint.name) {
        case 'xs': return 'max-width: 20vw;'
        case 'sm': return 'max-width: 20vw;'
        case 'md': return 'max-width: 25vw;'
        case 'lg': return 'max-width: 40vw;'
        case 'xl': return 'max-width: 50vw;'
      }
    }
  }
};
</script>